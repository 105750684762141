import { NgModule, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { ClipboardModule } from 'ngx-clipboard';
import { InlineSVGModule } from 'ng-inline-svg';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from 'src/environments/environment';

// Highlight JS
import { HighlightModule, HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import highlight from 'highlight.js/lib/highlight';
import xml from 'highlight.js/lib/languages/xml';
import json from 'highlight.js/lib/languages/json';
import scss from 'highlight.js/lib/languages/scss';
import typescript from 'highlight.js/lib/languages/typescript';

import { SplashScreenModule } from './_metronic/partials/layout/splash-screen/splash-screen.module';

import { NgxPermissionsModule } from 'ngx-permissions';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { metaReducers, reducers } from './_core/store';

import { AuthModule } from './modules/auth/auth.module';
import { ModuleGuard } from './_core/shared/guards/module.guard';
import { AuthInterceptorProviders } from './_core/shared/interceptors/token-interceptor';

import { CoreTranslateModule, CoreTranslateService, CORE_TRANSLATE_CONFIG_TOKEN } from '@ratkaiga/core/translate';
import { ScrollCoreModule } from './_core/scroll-core.module';
import { ToastrModule } from 'ngx-toastr';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// Import the library
import { NgxPanZoomModule } from 'ngx-panzoom';
import { NgScrollbarModule, NG_SCROLLBAR_OPTIONS } from 'ngx-scrollbar';
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { EventBusService } from './_core/shared/services/event-bus.service';
import { PusherService } from './_core/services/pusher.service';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

import { HammerModule } from '@angular/platform-browser';

import 'hammerjs';

/**
 * Import specific languages to avoid importing everything
 * The following will lazy load highlight.js core script (~9.6KB) + the selected languages bundle (each lang. ~1kb)
 */
export function getHighlightLanguages() {
  return [
    { name: 'typescript', func: typescript },
    { name: 'scss', func: scss },
    { name: 'xml', func: xml },
    { name: 'json', func: json },
  ];
}

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 3
};

/**
 * Ezt a funkciót egy APP_INITIALIZER tokennel hívjuk, amely még a teljes inicializáció előtt meg fogja hívni 
 * a translate végpontot és betölti a projekthez aktuálisan beállított (environment-ben) nyelvi állományt.
 * 
 * @param translateService 
 */
export function initializeTranslate(translateService: CoreTranslateService) {
  return (): Promise<any> => {
    return translateService.init();
  };
}

export function initEnvironment(): any {
  return environment;
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SplashScreenModule,
    HttpClientModule,
    HighlightModule,
    ClipboardModule,
    AppRoutingModule,
    InlineSVGModule.forRoot(),
    ToastrModule.forRoot(),
    NgbModule,
    NgApexchartsModule,
    NgxPermissionsModule.forRoot(),
    NgxDropzoneModule,
    NgxPanZoomModule,
    SwiperModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot({ stateKey: 'router' }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    AuthModule.forRoot(),
    CoreTranslateModule.forRoot({
      // tslint:disable-next-line:object-literal-shorthand
      environment: environment
    }),
    NgxMaskModule.forRoot(),
    HammerModule,
    NgScrollbarModule,
    ScrollCoreModule
  ],
  providers: [
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        languages: getHighlightLanguages,
      },
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeTranslate,
      deps: [CoreTranslateService],
      multi: true
    },
    {
      provide: CORE_TRANSLATE_CONFIG_TOKEN,
      useFactory: initEnvironment
    },
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    },
    AuthInterceptorProviders,
    ModuleGuard,
    EventBusService,
    PusherService
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule { }
