import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, NavigationEnd } from '@angular/router';
import { Observable, of } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { find } from 'lodash';
import { AppState } from '../../store';
import { AccessConfig, AccessConfigItem } from '../../config/access.config';
import { currentScopes } from '../../auth';
import { Entities } from '@ratkaiga/core';

@Injectable()
export class ModuleGuard implements CanActivate {
  constructor(private store: Store<AppState>, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

    const access = new AccessConfig();

    const permission = find(access.configs, (item: AccessConfigItem) => {
      return item.route === state.url;
    });

    if (permission === undefined) {
      return of(true);
    }

    return this.store
      .pipe(
        select(currentScopes),
        map((scopes: Entities.ScrollUserScope[]) => {

          const perm = find(scopes, (item: Entities.ScrollUserScope) => {
            return item.scope === permission.scope;
          });

          return perm ? true : false;
        }),
        tap(hasAccess => {
          if (!hasAccess) {
            this.router.navigateByUrl('/error/403');
          }

          return hasAccess;
        })
      );
  }
}
