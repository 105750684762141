<div class="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
  <!--begin::Signin-->
  <div class="login-form login-signin">
    <div class="text-center mb-10 mb-lg-20">
      <h3 class="font-size-h1">{{ 'label_forgot_password' | translate }}</h3>
      <p class="text-muted font-weight-bold">
        {{ 'label_forgot_password_help_text' | translate }}
      </p>
    </div>

    <kt-auth-notice></kt-auth-notice>

        <!--begin::Form-->
        <form class="form" [formGroup]="forgotPasswordForm" autocomplete="off" novalidate="novalidate" id="kt_login_form">
          <div class="form-group">
    
            <label class="form-label">{{ 'form_label_email' | translate }} <span class="text-danger">*</span></label>
            <input type="text" class="form-control" placeholder="{{ 'form_label_email' | translate }}" formControlName="email" />
            <kt-form-control-error [control]="forgotPasswordForm.controls['email']" [options]="{ minLength: 5 }" [description]="'Kérjük adja meg az e-mail címét'"></kt-form-control-error>
    
          </div>
      
          <!--begin::Action-->
          <div class="form-group d-flex flex-wrap justify-content-between align-items-center">
            <a href="javascript:;" routerLink="/auth/login" class="text-dark-50 text-hover-primary my-3 mr-2">{{ 'form_button_back_to_auth' | translate }}</a>
            <button id="kt_forgot_password_submit" (click)="submit()" [ngClass]="{
                'spinner spinner-right spinner-md spinner-light': loading
              }" class="btn btn-primary font-weight-bold px-9 py-4 my-3" [disabled]="isSubmitButtonDisabled">{{ 'form_button_send_email' | translate }}</button>
          </div>
          <!--end::Action-->

        </form>
        <!--end::Form-->

  </div>
</div>