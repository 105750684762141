import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class DebugService {

    private debug = environment.debug.useService;
    private ts = environment.debug.useTimestamps;

    /**
     * Visszaadjuk az aktuális időbélyeget (H:i:s)
     * @returns 
     */
    static getTime(): string {
        
        const d = new Date();         
        return d.getHours().toString() + ':' + d.getMinutes().toString() + ':' + d.getSeconds().toString();

    }

    /**
     * 
     * @param name 
     * @param file 
     */
    startNamedGroup(name: string, file?: string) {
        if (this.debug) {
            console.group((file) ? name + ' in ' + file : name);
        }
    }

    endGroup() {
        if (this.debug) {
            console.groupEnd();
        }
    }

    showPrintportalMessage(label: string): void {
        if (this.debug) {             
            console.log(((this.ts) ? DebugService.getTime() + ' ' : '') + '[PP Debug] => ' + label); 
        }
    }

    showPrintportalDebug(label: string, value: unknown, file = ''): void {
        if (this.debug) console.log(((this.ts) ? DebugService.getTime() + ' ' : '') + '[PP Debug] => ' + label, value, file);
    }
}
