// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appVersion: 'v061-pp',
  client: 'auth|916e51e4d99a265b2fb2773b032bec41',
  client_id: 0,
  debug: {
    useService: true,
    useTimestamps: true
  },
  pusher: {
    key: '5ca2257c9d3e46f4a58e',
    cluster: 'eu',
  },
  endpoints: {
    auth: 'https://staging.api.auth.scrollmax.net',
    app: 'https://staging.api.printportal.hu',
    locale: 'https://staging.api.printportal.hu',
    callas: 'https://staging.api.callas.scrollmax.net',
    fileservice: 'https://staging.api.fileservice.scrollmax.net'
  },
  localStorage: {
    tokenStorage: 'token-pp',
    translationStorage: 'lang-pp',
    partnerStorage: 'partner-pp',
  },
  authConfig: {
    isRegistrationEnabled: true,
    isForgotPasswordEnabled: true,
  },
  layout: {
    version: 'generic', // valid values: generic, keskeny
    controlConfigs: {
      dpRequiredDaysFromToday: 0, // anyagleadas elso valaszthato nap a mai naptol
      dpRequiredDaysFromAnyagleadas: 7, // szallitasi hatarido az anyagleadas datumatol
      pagerMaxButtonCount: 8,
      reorderLimit: false,
      reorderDayMax: 2,
      reorderNotificationEmail: 'gabor.ratkai@frxtechnology.com',
    },
    featureConfigs: {
      multiLanguage: true,
      jobFileUploadExcludes: [1, 1112, 114]
    },
    header: {
      visible: true,
      components: {
        notification: {
          visible: true,
          format: "dropdown"
        },
        languages: {
          visible: true,
          format: "dropdown"
        }
      },
    },
    aside: {
      components: {
        languages: false
      }
    },
    menuVisibility: {
      top: false,
      side: true,
    },
    userProfile: {
      type: 'dropdown',
      visible: false
    },
    localization: {
      generic: {
        default: 'hu',
        languages: [
          'hu',
          'en',
          'de'
        ],
      },
      keskeny: {
        default: 'hu',
        languages: [
          'hu',
          'en',
          'de'
        ],
      }
    },
    assets: {
      generic: {
        theme: 'dark',
        navicons: false,
        fronturls: {
          privacy: 'https://scrollmax.hu/adatvedelemi',
          contact: 'https://scrollmax.hu/kapcsolat'
        },
        splash: {
          logo: './assets/images/scroll-sm.png',
          alt: 'PrintPortal',
          mainclass: 'max-h-70px'
        },
        aside: {
          logo: {
            url: './assets/images/printportal_logo.png',
            mainclass: 'max-h-40px',
            height: '40px;',
          }
        },
        login: {
          layout: {
            theme: 'dark',
            background: './assets/images/backgrounds/main.jpg',            
            textclass: '',
            brand: '',
            usePoweredBy: false,
          },
          logo: {
            url: './assets/images/printportal_logo.png',
            class: 'max-h-70px',
            centered: true,
            brand: '',          
          }
        }
      },
      keskeny: {
        theme: 'keskeny',
        navicons: true,
        fronturls: {
          privacy: 'https://keskenynyomda.hu/adatkezelesi-tajekoztato',
          contact: 'https://keskenynyomda.hu/kapcsolat'
        },
        naviconPaths: [
          { tag: 'menu_label_dashboard', path: './assets/images/version/keskeny/navigation_buttons/home1.svg' },
          { tag: 'menu_label_offers', path: './assets/images/version/keskeny/navigation_buttons/ajanlatok1_jav.svg' },
          { tag: 'menu_label_jobticket_list', path: './assets/images/version/keskeny/navigation_buttons/megrendelt_munkak1.svg' },
          { tag: 'menu_label_invoices', path: './assets/images/version/keskeny/navigation_buttons/szamlak1.svg' },
          { tag: 'menu_label_partners', path: './assets/images/version/keskeny/navigation_buttons/sajat_ceg_adataim1.svg' },
          { tag: 'menu_label_logout', path: './assets/images/version/keskeny/navigation_buttons/kilep_ikon_out.svg' },
          { tag: 'settings', path: './assets/images/version/keskeny/setting_out.svg' },
          { tag: 'refresh', path: './assets/images/version/keskeny/refresh_icon_out.png' }
        ],
        iconPaths: [
          { tag: 'menu_label_dashboard', path: './assets/images/version/keskeny/navigation_buttons/home1.svg' },
          { tag: 'menu_label_offers', path: './assets/images/version/keskeny/navigation_buttons/ajanlatok1_jav.svg' },
          { tag: 'menu_label_jobticket_list', path: './assets/images/version/keskeny/navigation_buttons/megrendelt_munkak1.svg' },
          { tag: 'menu_label_invoices', path: './assets/images/version/keskeny/navigation_buttons/szamlak1.svg' },
          { tag: 'menu_label_partners', path: './assets/images/version/keskeny/navigation_buttons/sajat_ceg_adataim1.svg' },
          { tag: 'menu_label_logout', path: './assets/images/version/keskeny/navigation_buttons/kilep_ikon_out.svg' },
          { tag: 'settings', path: './assets/images/version/keskeny/setting_out.svg' },
          { tag: 'refresh', path: './assets/images/version/keskeny/refresh_icon_out.png' }
        ],
        splash: {
          logo: './assets/images/version/keskeny/keskeny_online_logo.png',
          alt: 'Keskeny Online',
          mainclass: 'max-h-70px'
        },
        aside: {
          logo: {
            url: './assets/images/version/keskeny/keskeny_online_logo.png',
            mainclass: 'max-h-40px',
            height: '43px'
          }
        },
        login: {
          layout: {
            theme: 'dark',
            background: './assets/images/version/keskeny/login_kep.jpg',            
            textclass: 'text-keskeny',
            brand: '',
            usePoweredBy: true,
          },
          logo: {
            url: './assets/images/version/keskeny/logo_magyar.png',
            class: '',
            centered: false,
            brand: '',          
          }
        }
      },
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
