export interface ITableColumns {
    table: string;
    key: string;
    width: {
        px?: number;
        percent?: number;
    };
    sortable: boolean;
    method: string;

    getMethod(): string;
    getMeasurement(method?: string): string;
}

export class TableColumns implements ITableColumns {

    table: string;

    key: string;

    width = { pixel: 128, percent: 15 };

    sortable: boolean;

    method = 'percent';

    m = { percent: '%', pixel: 'px' };

    setTable(table: string): TableColumns {
        this.table = table;
        return this;
    }

    getTable(): string {
        return this.table;
    }

    setKey(key: string): TableColumns {
        this.key = key;
        return this;
    }

    getKey(): string {
        return this.key;
    }

    setMethod(method: string): TableColumns {
        this.method = method;
        return this;
    }

    getMethod(): string {
        return this.method;
    }

    getMeasurement(method?: string): string {
        if (method) {
            return this.m[method];
        } else {
            return (this.method === 'percent') ? '%' : 'px';
        }
    }

    setWidthPercent(value: number): TableColumns {
        this.width.percent = value;
        this.method = 'percent';
        return this;
    }

    setWidthPixel(value: number): TableColumns {
        this.width.pixel = value;
        this.method = 'pixel';
        return this;
    }

    getWidth(method?: string): number {
        return this.width[this.method ?? method ?? 'percent'];
    }

    setSortable(value: boolean): TableColumns {
        this.sortable = value;
        return this;
    }

    getSortable(): boolean {
        return this.sortable;
    }

}