import { Injectable } from '@angular/core';
import { striptags } from 'striptags';

import { Entities } from '@ratkaiga/core';

@Injectable()
export class SanitizerService {

    constructor() { }

    /**
     * Ajánlati levél verzió felső része
     * 
     * @deprecated
     * @param rows 
     * @returns 
     */
    filterSubject(rows: Entities.ScrollPrintportalScrollLevel[]): Entities.ScrollPrintportalScrollLevel[] {
        return rows.filter(r => (r.getSorTipus() === 'megszolitas' || r.getSorTipus() === 'nagyszoveg')).slice(0, 2);
    }

    /**
     * Ajánlati levél verzió felső része
     * 
     * @param rows 
     * @returns 
     */
    filterSubjectSp(rows: Entities.ScrollPrintportalSpLevelsor[]): Entities.ScrollPrintportalSpLevelsor[] {
        return (rows) ? rows.filter(r => (r.getSorTipus() === 'megszolitas' || r.getSorTipus() === 'nagyszoveg')).slice(0, 2) : [];
    }

    /**
     * Ajánlati levél címsora
     * 
     * @deprecated
     * @param rows 
     * @returns 
     */
    filterTitle(rows: Entities.ScrollPrintportalScrollLevel[]): Entities.ScrollPrintportalScrollLevel {
        return rows.filter(r => (r.getSorTipus() === 'cimsor'))[0];
    }


    /**
     * Ajánlati levél címsora
     * 
     * @param rows 
     * @returns 
     */
    filterTitleSp(rows: Entities.ScrollPrintportalSpLevelsor[]): Entities.ScrollPrintportalSpLevelsor {
        return (rows) ? rows.filter(r => (r.getSorTipus() === 'cimsor'))[0] : new Entities.ScrollPrintportalSpLevelsor();           
    }

    /**
     * Ajánlati levél címsora
     * 
     * @deprecated
     * @param rows 
     * @returns 
     */
    filterDatarows(rows: Entities.ScrollPrintportalScrollLevel[]): Entities.ScrollPrintportalScrollLevel[] {
        return rows.filter(r => (r.getSorTipus() === 'adatsor' || r.getSorTipus() === 'arsor' || r.getSorTipus() === 'belsonagyszoveg' || r.getSorTipus() === 'ppremark'));
    }

    /**
     * Ajánlati levél címsora
     * 
     * @param rows 
     * @returns 
     */
    filterDatarowsSp(rows: Entities.ScrollPrintportalSpLevelsor[]): Entities.ScrollPrintportalSpLevelsor[] {
        return rows.filter(r => (r.getSorTipus() === 'adatsor' || r.getSorTipus() === 'arsor' || r.getSorTipus() === 'belsonagyszoveg' || r.getSorTipus() === 'ppremark'));
    }

    /**
     * Ajánlati levél footer része, amelyben nincs benne az aláírás
     * 
     * @deprecated
     * @param rows 
     * @returns 
     */
    filterFooter(rows: Entities.ScrollPrintportalScrollLevel[]): Entities.ScrollPrintportalScrollLevel[] {
        return rows.filter(r => r.getSorTipus() === 'nagyszoveg');
    }

    /**
     * Ajánlati levél footer része, amelyben nincs benne az aláírás
     * 
     * @param rows 
     * @returns 
     */
    filterFooterSp(rows: Entities.ScrollPrintportalSpLevelsor[]): Entities.ScrollPrintportalSpLevelsor[] {
        return rows.filter(r => r.getSorTipus() === 'nagyszoveg');
    }

    filterTags(rows: Entities.ScrollPrintportalScrollLevel[]): Entities.ScrollPrintportalScrollLevel {
        return rows.filter(r => r.getSorTipus() === 'ppremark')[0];
    }

    filterTagsSp(rows: Entities.ScrollPrintportalSpLevelsor[]): Entities.ScrollPrintportalSpLevelsor {
        return rows.filter(r => r.getSorTipus() === 'ppremark')[0];
    }

    /**
     * Tetszőleges tag-et filterez nekünk az adatsorokbóól
     * 
     * @deprecated
     * @param rows 
     * @returns 
     */
    filterCustomTag(rows: Entities.ScrollPrintportalScrollLevel[], tag: string): Entities.ScrollPrintportalScrollLevel[] {
        return rows.filter(r => r.getSorTipus() === tag);
    }

    /**
     * Tetszőleges tag-et filterez nekünk az adatsorokbóól
     * 
     * @deprecated
     * @param rows 
     * @returns 
     */
    filterCustomTagSp(rows: Entities.ScrollPrintportalSpLevelsor[], tag: string): Entities.ScrollPrintportalSpLevelsor[] {
        return rows.filter(r => r.sortipus === tag);
    }

    /**
     * Bejövő stringet strippelünk a PHP-hez hasonlóan, csak az engedélyezett elemek mehetnek tovább. 
     * Az &nbsp; jelölésekből szóközt csinálunk.
     * 
     * @param text 
     * @returns 
     */
    clearText(text: string): string {  

        // TODO az egészet try catchban futtatjuk, mert olyan adat minőség van, hogy simán ráfuthatunk egy text.replace hibára pl.
        try {
            const t = striptags(text.replace('<p>&nbsp;</p>', ''), { allowedTags: new Set(['p', 'br', 'a', 'img']) }).replace('&nbsp;', ' ');                
            return (t.indexOf(':') > -1) ? t.substring(0, t.length - 1) : t;
        } catch (e) {
            return text;
        }    

    }

    /**
     * Az allowed tömbben megadott HTML elemeket átengedjük, a többit strippeljük
     * 
     * @param text 
     * @param allowed 
     * @returns 
     */
    clearUnallowedText(text: string, allowed: string[]): string {
        const t = striptags(text, { allowedTags: new Set(allowed) }).replace('&nbsp;', ' ');
        return (t.indexOf(':') > -1) ? t.substring(0, t.length - 1) : t;
    }

    /**
     * Minden HTML elemet strippelünk
     * 
     * @param text 
     * @returns 
     */
    clearHTMLText(text: string): string {
        const t = striptags(text).replace('&nbsp;', ' ');
        return (t.indexOf(':') > -1) ? t.substring(0, t.length - 1) : t;
    }

    splitParagraph(text: string): string[] {
        const t = [];
        text.split('<BR>').forEach(p => {
            t.push(this.clearScrollText(p));
        });

        return t;
    }

    clearScrollText(text: string): string {
        text = text.replace('\r<BR', '');
        text = text.replace('\r', '');

        return text;
    }

    static validate = {

        isEmailAddress: (str): boolean => {
            const pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            return pattern.test(str);  // returns a boolean
        },
        isNotEmpty: (str): boolean => {
            const pattern = /\S+/;
            return pattern.test(str);  // returns a boolean
        },
        isNumber: (str): boolean => {
            const pattern = /^\d+$/;
            return pattern.test(str);  // returns a boolean
        },
        isSame: (str1, str2): boolean => {
            return str1 === str2;
        },
        onlyNumeric: (event: any): void => {

            const regexpNumber = /[0-9\+\-\ ]/;

            const inputCharacter = String.fromCharCode(event.charCode);
            if (event.keyCode != 8 && !regexpNumber.test(inputCharacter)) {
                event.preventDefault();
            }

        }


    }

    static clear = {

        phoneNumber: (str): string => {
            try {
                return str.replace(/[^\d]/g, '');
            } catch (e) {
                return '';
            }
        }

    }

    static serializeParam(value: string): string {
        return (value) ? value.replace(/\+/gi, '%2B') : '';
    }

    static serialize(value: string): string {
        return SanitizerService.serializeParam(value);
    }

    static unserialize(value: string): string 
    {
        let ret = (value) ? value.replace(/%2B/gi, '+') : '';
            ret = (ret) ? ret.replace(/%20/gi, '+') : '';

        return ret;
    }

    static nl2br(value: string): string {
        if (value) {
            return value.replace(/(?:\r\n|\r|\n)/g, '<br>');
        }
        
        return value;
    }

}
