import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Entities } from '@ratkaiga/core';

export interface IChatLoader {
    allowed: boolean; // ha nem akkor nem jelenítjük meg a chat gombot sem
    source: string; // a típusnak felel meg
    refid: number; // a tipushoz tartozó id
    title?: string;
}

export interface ISoftproofPage {
    hash: string;
    oldal: number;
    oldal_kod?: number;
    allapot?: number;
    controls: boolean;
}

export interface IOrderForm {
    tab: number;
}

export interface IOrderFormData extends IOrderForm {
    anyagle: NgbDate;
    anyagleido?: string;
    anyagle_nev: number;
    cim: string;
    gepindulas: boolean;
    gepindulas_nev?: number;
    gepindulas_megjegyzes?: string;    
    imprimalas_korrektura: boolean;
    imprimalas_nev?: number;
    imprimalas_megjegyzes?: string;
    imprimalas_proofoptions?: string;
    szallido?: string;
    szallitas: NgbDate;
    termekdij: number;
    termekdijEntity: Entities.ScrollPrintportalTermekdij; 
    megjegyzesek: string;
    po: string;
    cikkszam: string;
    isbn: string;
    szamlazasi_cim: number;
    szallitasi_cim: number;
    szemelyes_atvetel: boolean;
}

export interface IPrintportalRendelesAdat {
    adat: string;
    cimke: string;
    kod: number;
    scrlev_adattipus: string;
    tagkod: number;
    tipus: string;

    getAdat(): string;
    getCimke(): string;
    getKod(): number;
    getAdattipus(): string;
    getTagKod(): number;
    
    getEgyediAdattipus(): string;
    getMezoTipus(): string;
}

export class RendelesAdat implements IPrintportalRendelesAdat {

    adat: string;    
    cimke: string;
    kod: number;
    scrlev_adattipus: string;
    tagkod: number;
    tipus: string;
    editable = false;

    setAdat(adat: string): RendelesAdat {
        this.adat = adat;
        return this;
    }

    getAdat(): string {
        return this.adat;
    }

    setCimke(cimke: string): RendelesAdat {
        this.cimke = cimke;
        return this;
    }

    getCimke(): string {
        return this.cimke;
    }

    setKod(kod: number): RendelesAdat {
        this.kod = kod;
        return this;
    }

    getKod(): number {
        return this.kod;
    }

    setAdattipus(scrlev_adattipus: string): RendelesAdat {
        this.scrlev_adattipus = scrlev_adattipus;
        return this;
    }

    getAdattipus(): string {
        return this.scrlev_adattipus;
    }

    setTagKod(tagkod: number): RendelesAdat {
        this.tagkod = tagkod;
        return this;
    }

    getTagKod(): number {
        return this.tagkod;
    }

    getEgyediAdattipus(): string {
        return this.scrlev_adattipus + '_' + this.kod.toString();
    }

    setMezoTipus(tipus: string): RendelesAdat {
        this.tipus = tipus;
        return this;
    }

    getMezoTipus(): string {
        return this.tipus;
    }

    setEditable(editable: boolean): RendelesAdat {
        this.editable = editable;
        return this;
    }

    isEditable(): boolean {
        return this.editable;
    }
    
}

export interface IPrintportalNextgenOfferPrices {
    kod: number;
    rendeles: number;
    ajanlat: number;
    cimke: string;
    adat: string;
}

export interface IPrintportalNextgenOfferDatarows {
    kod: number;
    cimke: string;
    adat: string;
    scrlev_adattipus: string;
    tagkod: number;
}

export interface IPrintportalNextgenOfferVersion {
    level: number;
    version: number;    
    title: string;
    description: string;    
    arak: IPrintportalNextgenOfferPrices[];
    adatsorok: IPrintportalNextgenOfferDatarows[];
    kelt: string;
}

export interface IPrintportalOfferData {
    kod: number;
    data: Entities.ScrollPrintportalScrollLevel[];
}

export enum PrintportalOfferDataEnum {
    ALL, // minden mező
    DATA, // adatok
    EDITABLE, // csak változtatható paraméterek (megrendeléshey pl.)
}

export type PrintportalOfferDataType =
    PrintportalOfferDataEnum.ALL |
    PrintportalOfferDataEnum.DATA |
    PrintportalOfferDataEnum.EDITABLE;

export interface IPrintportalFormOptions {
    index: number;
    options: IPrintportalFormValidatorOptions[];
}

export interface IPrintportalFormValidatorOptions {
    controlName?: string;
    minLength?: number;
    maxLength?: number;
    type?: string;
}

export interface IBoxedSelectInit<T> {
    instance: string;
    update?: boolean;
    payload: T[];
}

export class BoxedSelectInit<T> implements IBoxedSelectInit<T> {
    instance: string;
    update?: boolean;
    payload: T[] = [];
}

export interface IPrintportalUserSelect {
    id?: number;
    kod: number;
    nev: string;
    beosztas?: string;
    tel?: string;
    tel2?: string;
    mobil?: string;
    email: string;
    selected: boolean;
}

export class PrintportalFieldErrorEntity {
    code: string;
    field: string;
    message: string;
    type: string;

    getCode(): string {
        return this.code;
    }

    getField(): string {
        return this.field;
    }

    getMessage(): string {
        return this.message;
    }

    getType(): string {
        return this.type;
    }

    setValues(v: any): PrintportalFieldErrorEntity {

        Object.keys(v).forEach(key => {
            this[key] = v[key];
        });

        return this;
    }
}

export class PrintportalOrderEntity {

    levelfo: number;
    cim: string;
    anyagle: string;
    anyagleido: string;
    szallitas: string;
    szallido: string;
    po: string;
    anyagle_user_kod: number;
    anyagle_user_name: string;
    anyagle_user_phone: string;
    anyagle_user_email: string;
    imprimalas_korrektura = false;
    imprimalas_megjegyzes: string;
    imprimalas_user_name: string;
    imprimalas_user_phone: string;
    imprimalas_user_email: string;
    gepindulas_jelen = false;
    gepindulas_megjegyzes: string;
    gepindulas_user_name: string;
    gepindulas_user_phone: string;
    gepindulas_user_email: string;
    termekdij: number;
    szamlacim_nev: string;
    szamlacim_orszag: string;
    szamlacim_telepules: string;
    szamlacim_utca: string;
    szamlacim_irsz: string;
    adoszam: string;
    megjegyzes: string;

    hazhozszallitast_ker = false;
    szallitasicim_cegnev: string;
    szallitasicim_orszag: string;
    szallitasicim_telepules: string;
    szallitasicim_utca: string;
    szallitasicim_irsz: string;
    szallitasi_ido: string;

    parameterek: { key: string; value: string }[] = [];

}

export interface ICoreDictionary {
    key: string;
    value: unknown;
}

export class CoreDictionary implements ICoreDictionary {

    key: string;

    value: unknown;

    setKey(value: string): CoreDictionary {
        this.key = value;
        return this;
    }

    getKey(): string {
        return this.key;
    }

    setValue(value: unknown): CoreDictionary {
        this.value = value;
        return this;
    }

    getValue(): unknown {
        return this.value;
    }
}

export interface IPrintportalParameterFields {
    label: string;
    name: string;
    value: unknown;
    type: string;
    static?: boolean;
    code?: number;
    freshvalue?: string;
    question?: boolean;
}

export class PrintportalOrderParameterFields implements IPrintportalParameterFields {

    label: string;

    name: string;

    description: string;

    value: unknown;

    code?: number;

    type: string;

    static?: boolean = false;

    freshvalue?: string;

    question?: boolean = false;

    required = false;

    setRequired(value: boolean): PrintportalOrderParameterFields {
        this.required = value;
        return this;
    }

    isRequired(): boolean {
        return this.required;
    }

    setQuestion(value: boolean): PrintportalOrderParameterFields {
        this.question = value;
        return this;
    }

    isQuestion(): boolean {
        return this.question;
    }

    setLabel(value: string): PrintportalOrderParameterFields {
        this.label = value;
        return this;
    }

    getLabel(): string {
        return this.label;
    }

    setDescription(description: string): PrintportalOrderParameterFields {
        this.description = description;
        return this;
    }

    getDescription(): string {
        return this.description;
    }

    setName(value: string, appendCode?: boolean): PrintportalOrderParameterFields {
        this.name = (appendCode) ? value + '_' + this.code.toString() : value;
        return this;
    }

    getName(): string {
        return this.name;
    }

    setValue(value: unknown): PrintportalOrderParameterFields {
        this.value = value;
        return this;
    }

    getValue(): unknown {
        return this.value;
    }

    setCode(value: number): PrintportalOrderParameterFields {
        this.code = value;
        return this;
    }

    getCode(): number {
        return this.code;
    }

    setType(value: string): PrintportalOrderParameterFields {
        this.type = value;
        return this;
    }

    getType(): string {
        return this.type;
    }

    setStatic(): PrintportalOrderParameterFields {
        this.static = true;
        this.type = 'static';
        return this;
    }

    isStatic(): boolean {
        return this.static;
    }

    setFreshValue(value: string): PrintportalOrderParameterFields {
        this.freshvalue = value;
        return this;
    }

    getFreshValue(): string {
        return this.freshvalue;
    }

}

export class PrintportalOrderFields {

    private _fields = [
        { key: 'levelfo', value: 'pp_rendeles' },                               // Aktuális levélfő
        { key: 'cim', value: 'pp_rendeles' },                                   // Megnevezés *
        { key: 'anyagle', value: 'pp_rendeles' },                               // Anyagleadás dátum *
        { key: 'anyagleido', value: 'pp_rendeles' },                            // Anyagleadás * / Megjegyzés / óra / napszak
        { key: 'szallitas', value: 'pp_rendeles' },                             // Határidő *
        { key: 'szallido', value: 'pp_rendeles' },                              // Határidő * / Megjegyzés / óra / napszak
        { key: 'po', value: 'pp_rendeles_reszletek' },                          // Vevői rendeléskód (PO)
        { key: 'anyagle_user_name', value: 'pp_rendeles_reszletek' },           // Anyagleadás kapcsolattartó * / Név *
        { key: 'anyagle_user_email', value: 'pp_rendeles_reszletek' },          // Anyagleadás kapcsolattartó * / Email *
        { key: 'anyagle_user_phone', value: 'pp_rendeles_reszletek' },          // Anyagleadás kapcsolattartó * / Telefonszám *
        { key: 'imprimalas_korrektura', value: 'pp_rendeles_reszletek' },       // Jóváhagyás / Imprimálás *
        { key: 'imprimalas_megjegyzes', value: 'pp_rendeles_reszletek' },       // Jóváhagyás / Imprimálás * / Megjegyzés
        { key: 'imprimalas_user_name', value: 'pp_rendeles_reszletek' },        // Jóváhagyás kapcsolattartó * / Név *
        { key: 'imprimalas_user_email', value: 'pp_rendeles_reszletek' },       // Jóváhagyás kapcsolattartó * / Email *
        { key: 'imprimalas_user_phone', value: 'pp_rendeles_reszletek' },       // Jóváhagyás kapcsolattartó * / Telefonszám *
        { key: 'gepindulas_jelen', value: 'pp_rendeles_reszletek' },            // Gépindulásnál *
        { key: 'gepindulas_megjegyzes', value: 'pp_rendeles_reszletek' },       // Gépindulásnál * / Megjegyzés
        { key: 'gepindulas_user_name', value: 'pp_rendeles_reszletek' },        // Gépindulás kapcsolattartó * / Név *
        { key: 'gepindulas_user_email', value: 'pp_rendeles_reszletek' },       // Gépindulás kapcsolattartó * / Email *
        { key: 'gepindulas_user_phone', value: 'pp_rendeles_reszletek' },       // Gépindulás kapcsolattartó * / Telefonszám *
        { key: 'termekdij', value: 'pp_rendeles_reszletek' },                   // Termékdíj-kategória *
        { key: 'szamlacim_nev', value: 'pp_rendeles_reszletek' },               // Számlázási adatok * / Cégnév *
        { key: 'szamlacim_orszag', value: 'pp_rendeles_reszletek' },            // Számlázási adatok * / Ország *
        { key: 'szamlacim_telepules', value: 'pp_rendeles_reszletek' },         // Számlázási adatok * / Település *
        { key: 'szamlacim_utca', value: 'pp_rendeles_reszletek' },              // Számlázási adatok * / Utca *
        { key: 'szamlacim_irsz', value: 'pp_rendeles_reszletek' },              // Számlázási adatok * / Irszám *
        { key: 'adoszam', value: 'pp_rendeles_reszletek' },                     // Adószám
        { key: 'megjegyzes', value: 'pp_rendeles_reszletek' },                  // Megjegyzés
        { key: 'szallitasi_ido', value: 'pp_rendeles_reszletek' },
        { key: 'hazhozszallitast_ker', value: 'pp_rendeles_reszletek' },
        { key: 'szallitasicim_cegnev', value: 'pp_rendeles_reszletek' },
        { key: 'szallitasicim_orszag', value: 'pp_rendeles_reszletek' },
        { key: 'szallitasicim_telepules', value: 'pp_rendeles_reszletek' },
        { key: 'szallitasicim_utca', value: 'pp_rendeles_reszletek' },
        { key: 'szallitasicim_irsz', value: 'pp_rendeles_reszletek' }
    ];

    private _dynFields = [
        { key: '01meret', value: 'pp_rendeles_parameterek' },
        { key: '02terjedelem', value: 'pp_rendeles_parameterek' },
        { key: '03pld', value: 'pp_rendeles_parameterek' },
        { key: '04hozott', value: 'pp_rendeles_parameterek' },
        { key: '05tag', value: 'pp_rendeles_parameterek' },
        { key: '06koteszet', value: 'pp_rendeles_parameterek' }
    ];

    private _dynFieldTypes = {
        '01meret': 'text',
        '02terjedelem': 'text',
        '03pld': 'text',
        '04hozott': 'text',
        '05tag': 'text',
        '06koteszet': 'text'
    };

    getParamContainerName(key: string): string {
        try {
            return this._fields.filter(r => r.key === key)[0].value;
        } catch (e) {

            // console.log('Key (' + key + ') not assignable to standard printportal order fields, trying to get dynamic field container');
            const c = this._dynFields.filter(r => r.key === key);
            if (c.length === 0) {
                let r;
                this._dynFields.forEach((e) => {
                    if (key.indexOf(e.key)) {
                        r = e.value;
                    }
                });

                if (r) {
                    return r;
                } else {
                    throw Error('FATAL: FormControl name ' + key + ' does not exist in the parameters dictionary');
                }
            } else {
                return c[0].value;
            }
        }
    }

    getDynFieldType(key: string): string {

        let t;
        Object.keys(this._dynFieldTypes).forEach((e) => {
            if (e === key || key.indexOf(e) > -1) {
                t = this._dynFieldTypes[e];
            }
        });

        return t;
    }

}