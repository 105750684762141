declare const Pusher: any;

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CoreService } from '../shared/services';

@Injectable()
export class PusherService {

      pusher: any;

      /**
       * Privát channel, amely felhasználóhoz van rendelve
       */
      channel: any;

      constructor(private http: HttpClient, private coreService: CoreService) {
        
        this.pusher = new Pusher(environment.pusher.key, {
          cluster: environment.pusher.cluster,
          encrypted: true
        });

        this.pusher.logToConsole = true;
        this.pusher.connection.bind( 'error', function( err ) {
          if(err.error.data.code === 4004) {
            console.log('Over limit!');
          } else {
            console.log('General socket error:', err);
          }
        });

        if (this.initUserChannel()) {
          console.log('User channel initialized');
        } else {
          console.log('User channel not initialized, because user identification not present at a moment')
        }
        
      }

      /**
       * Felépítjük a felhasználó által használt pusher channel-t
       * 
       * @returns 
       */
      initUserChannel(): boolean {
        
        try {
          
          if (!this.isChannelSubscribed(`user-${this.coreService.currentUser.getId()}`)) {
            this.channel = this.pusher.subscribe(`user-${this.coreService.currentUser.getId()}`);          
          }

        } catch (e) {
          // nincs user id, tehát a service nem tud elsődleges user channelt inicializálni
          return false;          
        }

        return true;
      }

      /**
       * Egy adott channel nevet ellenőriz a feliratkozások között
       * 
       * @param channel 
       * @returns 
       */
      isChannelSubscribed(channel: string): boolean {
        
        let a = false;
        this.pusher.allChannels().forEach(c => {
          if (c.name === channel) a = true;
        });

        return a;
      }

      /**
       * 
       * @param event 
       * @param data 
       */
      sendOwnMessage(event: string, data: unknown): void {
        
        if (!this.isChannelSubscribed(`user-${this.coreService.currentUser.getId()}`)) {
          console.log(`Channel user-${this.coreService.currentUser.getId()} not found, re-subscribe now`);
          this.initUserChannel();
        }

        this.pusher.trigger(`user-${this.coreService.currentUser.getId()}`, event, data);
      }

      getSubscribedChannel(channel: string): unknown {
        return this.pusher.channel(channel);
      }

      subscribeUserEvent(event: string, callback: (data: unknown, parent: unknown) => void, parent: unknown): void {        
        this.channel.bind(event, data => callback(data, parent));
      }

    }
