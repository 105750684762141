import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Params, Router } from '@angular/router';
import { AuthNoticeService, CoreService, SanitizerService } from 'src/app/_core/shared/services';
import { CoreTranslateService } from '@ratkaiga/core/translate';
import { Subject, Subscription } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { HttpParams } from '@angular/common/http';
import { CoreResponseError, CoreResponse, Entities, CoreItemEntity  } from '@ratkaiga/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'kt-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit, OnDestroy {

  changePasswordForm: FormGroup;

	loading = false;

	errors: any = [];

  subscriptions: Subscription[] = [];

  isSubmitButtonDisabled = false;

  destroy$: Subject<void> = new Subject();

  slug: string;
  email: string;

  constructor(private translateService: CoreTranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private coreService: CoreService,
    private authNoticeService: AuthNoticeService) { }

  ngOnInit(): void {

    this.changePasswordForm = new FormGroup({
      email: new FormControl('',
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.minLength(5),
          Validators.maxLength(320), // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
        ])),
      password: new FormControl('', Validators.compose([
          Validators.required,
          Validators.minLength(6)
      ])),
      confirmPassword: new FormControl('', Validators.compose([
          Validators.required,
          Validators.minLength(6)
      ]))
    }, { validators: this.checkPasswords });

    this.subscriptions.push(this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((r: ParamMap) => {
      this.slug = r.get('slug');
    }));

    this.subscriptions.push(this.route.queryParamMap.pipe(takeUntil(this.destroy$)).subscribe((r: ParamMap) => {
      if (r.has('email')) {
        this.changePasswordForm.controls.email.setValue(r.get('email').replace(/\s/g , "+"));
        this.changePasswordForm.controls.password.setValue('');
        this.changePasswordForm.controls.confirmPassword.setValue('');
      }
    }));

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  submit(event: any): void {
    
    event.preventDefault();
    event.stopPropagation();

    this.isSubmitButtonDisabled = true;

    if (this.changePasswordForm.valid) {

      let params = new HttpParams();
      params = params.set('code', this.slug);
      params = params.set('email', SanitizerService.serialize(this.changePasswordForm.controls.email.value));
      params = params.set('password', this.changePasswordForm.controls.password.value);
      params = params.set('nyomda_id', environment.client_id.toString());

      this.coreService.changePassword(params).subscribe((r: CoreItemEntity) => {
        
        this.authNoticeService.setNotice('Jelszava megváltozott! Hamarosan a belépéshez irányítjuk', 'success');
        this.navigateToLogin();

      },        
      (e: CoreResponseError) => {

        this.authNoticeService.setNotice('Érvénytelen jelszóváltoztatási kísérlet', 'danger');
        
        this.changePasswordForm.reset();
        this.isSubmitButtonDisabled = false;
        
      });

      // valid
    } else {
      console.log('invalid');
    }

  }

  navigateToLogin(): void {
    setTimeout(() => {
      this.router.navigateByUrl('/auth/login');
    }, 5000);
  }

  checkPasswords: ValidatorFn = (group: AbstractControl):  ValidationErrors | null => { 
    const pass = group.get('password').value;
    const confirmPass = group.get('confirmPassword').value

    if (pass !== confirmPass) {
      group.get('confirmPassword').setErrors({ notSame: true });
    }

    return pass === confirmPass ? null : { notSame: true }
  }

}
