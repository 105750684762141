<div class="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
  <!--begin::Signin-->
  <div class="login-form login-signin">
    <div class="text-center mb-10 mb-lg-20">
      <h3 class="font-size-h1">{{ 'label_sign_in' | translate }}</h3>
      <p class="text-muted font-weight-bold">
        {{ 'label_type_your_username_and_password' | translate }}
      </p>
    </div>

    <kt-auth-notice></kt-auth-notice>

    <div class="text-right" *ngIf="languageSelector">
      <span *ngFor="let lang of languages"><a href="javascript:;" (click)="changeLanguage(lang.value)"><img src="assets/media/languages/{{ lang.value }}.png" alt="{{ lang.label }}" style="max-width: 16px; margin: 5px;"></a></span>
    </div>

    <!--begin::Form-->
    <form class="form" [formGroup]="loginForm" autocomplete="off" novalidate="novalidate" id="kt_login_form">
      <div class="form-group">

        <label class="form-label">{{ 'form_label_email' | translate }} <span class="text-danger">*</span></label>
        <input type="text" class="form-control" placeholder="{{ 'form_label_email' | translate }}" formControlName="email" />
        <kt-form-control-error [control]="loginForm.controls['email']" [options]="{ minLength: 5 }" [description]="'Kérjük adja meg az e-mail címét'"></kt-form-control-error>

      </div>
      <div class="form-group">

        <label class="form-label">{{ 'form_label_password' | translate }} <span class="text-danger">*</span></label>
        <input type="password" class="form-control" placeholder="{{ 'form_label_password' | translate }}" formControlName="password" autocomplete="off" />
        <kt-form-control-error [control]="loginForm.controls['password']" [options]="{ minLength: 5 }" [description]="'Kérjük adja meg a jelszavát'"></kt-form-control-error>

      </div>
      <!--begin::Action-->
      <div class="form-group d-flex flex-wrap justify-content-between align-items-center">
        <a href="javascript:;" routerLink="/auth/forgot-password" class="text-dark-50 text-hover-primary my-3 mr-2" id="kt_login_forgot">{{ 'form_button_forgot_password' | translate }}</a>
        <button id="kt_login_signin_submit" (click)="submit()" [ngClass]="{
            'spinner spinner-right spinner-md spinner-light': loading
          }" class="btn btn-primary font-weight-bold px-9 py-4 my-3">{{ 'form_button_signin' | translate }}</button>
      </div>
      <!--end::Action-->
    </form>
    <!--end::Form-->
  </div>
  <!--end::Signin-->
</div>