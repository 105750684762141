export const DynamicAsideMenuConfig = {
  items: [
    {
      section: 'Main Data',
      translate: 'menu_section_portal',      
      visibility: {
        generic: true,
        keskeny: true,
      },
      scope: 'pp:access'
    },    
    {
      title: 'Dashboard',
      root: true,
      icon: 'flaticon2-architecture-and-city',
      svg: './assets/media/svg/icons/Design/Layers.svg',
      page: '/dashboard',
      translate: 'menu_label_dashboard',
      bullet: 'dot',
      fname: undefined,
      visibility: {
        generic: true,
        keskeny: true,
      },
      scope: 'pp:access'
    },
    {
      title: 'Ajánlatok',
      root: true,
      icon: 'flaticon2-architecture-and-city',
      svg: './assets/media/svg/icons/Communication/Active-call.svg',
      page: '/portal/offers',
      translate: 'menu_label_offers',
      bullet: 'dot',
      fname: undefined,
      visibility: {
        generic: true,
        keskeny: true,
      },
      scope: 'pp:access'
    },
    {
      title: 'Táska lista',
      root: true,
      icon: 'flaticon2-architecture-and-city',
      svg: './assets/media/svg/icons/Shopping/Bag1.svg',
      page: '/portal/jobs',
      translate: 'menu_label_jobticket_list',
      bullet: 'dot',
      fname: undefined,
      visibility: {
        generic: true,
        keskeny: true,
      },
      scope: 'pp:access'
    },
    {
      title: 'Számlák',
      root: true,
      icon: 'flaticon2-architecture-and-city',
      svg: './assets/media/svg/icons/Communication/Mail-attachment.svg',
      page: '/portal/invoices',
      translate: 'menu_label_invoices',
      bullet: 'dot',
      fname: undefined,
      visibility: {
        generic: true,
        keskeny: true,
      },
      scope: 'pp:access'
    },
    /*
    {
      title: 'Callas PDF',
      root: true,
      icon: 'flaticon2-architecture-and-city',
      svg: './assets/media/svg/icons/Tools/Pantone.svg',
      page: '/portal/callas',
      translate: 'menu_label_callas_pdf',
      bullet: 'dot',
      fname: undefined,
      visibility: {
        generic: true,
        keskeny: false,
      },
      scope: 'pp:access'
    }, 
    */
    {
      title: 'Partnerek szerkesztése',
      root: true,
      icon: 'flaticon2-architecture-and-city',
      svg: './assets/media/svg/icons/Map/Marker1.svg',
      page: '/portal/partners',
      translate: 'menu_label_partners',
      bullet: 'dot',
      fname: undefined,
      visibility: {
        generic: true,
        keskeny: true,
      },
      scope: 'pp:access'
    }, 
    /*
    {
      section: 'Administration',
      translate: 'menu_section_administration',
      visibility: {
        generic: true,
        keskeny: true,
      },
      scope: 'pp:admin'
    },
    */
    {
      title: 'Adminisztráció',
      root: true,
      bullet: 'dot',
      page: '/admin',
      icon: 'flaticon2-browser-2',
      svg: './assets/media/svg/icons/General/Settings-2.svg',
      translate: 'menu_label_administration',
      visibility: {
        generic: true,
        keskeny: true,
      },
      scope: 'pp:admin',
      submenu: [
        {
          title: 'Forditasok',
          bullet: 'dot',
          page: '/admin/translate',
          translate: 'menu_label_translations',
          fname: undefined,
          visibility: {
            generic: true,
            keskeny: true,
          },
          scope: 'pp:admin'
        },
        {
          title: 'Changelist',
          bullet: 'dot',
          page: '/admin/changelist',
          translate: 'menu_label_changelist',
          fname: undefined,
          visibility: {
            generic: true,
            keskeny: true,
          },
          scope: 'pp:admin'
        }               
      ]
    },
    {
      title: 'Logout',
      root: true,
      icon: 'flaticon2-architecture-and-city',
      svg: './assets/media/svg/icons/Navigation/Sign-out.svg',
      page: '',
      translate: 'menu_label_logout',
      bullet: 'dot',
      fname: 'logout',
      visibility: {
        generic: true,
        keskeny: true,
      },
      scope: 'pp:access'
    },
  ]
};