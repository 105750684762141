/**
 * Modal ablakok lehetséges állapotai
 */
export enum ModalStatesEnum {
    CREATE = 'create',
    CHANGE = 'change',
    DELETE = 'delete',
    CANCEL = 'cancel',
    CUSTOM = 'custom'
}

/**
 * Modal ablakok állapot típusai
 */
export type ModalStateType = ModalStatesEnum.CREATE | ModalStatesEnum.CHANGE | ModalStatesEnum.DELETE | ModalStatesEnum.CANCEL | ModalStatesEnum.CUSTOM;

/**
 * Modalok esetében használjuk ezt az interface-t az opció lehetőségek előírásához
 */
export interface IModalOptions {

    state: ModalStateType;

    title: string;

    buttons: {
        ok: string;
        cancel: string;
    };

    buttonColors: {
        ok: string;
        cancel: string;
    };

    extra: any;

}

/**
 * Modalok esetén használjuk ezt az objektumot az állapot és az opciók átadásához
 */
export class ModalOptions implements IModalOptions {

    state: ModalStateType;

    title: string = '';

    buttons: any = {
        ok: '',
        cancel: ''
    };

    buttonColors: any = {
        ok: 'btn-secondary',
        cancel: 'btn-primary'
    };

    extra: any = {};

}

export class ModalCallback<T> {

    state: ModalStateType;

    payload: T;

    options: any;

    extra: any;

}