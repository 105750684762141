import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Entities, CoreResponseError, CoreError } from '@ratkaiga/core';

import {
  IModalOptions,
  ModalCallback,
  ModalOptions,
  ModalStateType,
  ModalStatesEnum
} from 'src/app/_core/shared/interfaces/index';
import { runInThisContext } from 'vm';

@Component({
  selector: 'kt-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss']
})
export class ErrorModalComponent implements OnInit {

  public states = ModalStatesEnum;

  /**
   * Egyedileg hozunk létre error textet
   */
  errorText: string = '';

  /**
   * A listánkhoz kell egy egyedi array
   */
  errorArray: CoreError[] = [];

  /**
   * Modal adatok átadása (a payload lehet bármi gyakorlatilag, modal komponens függő)
   */
  @Input() payload: CoreResponseError;

  /**
   * Modal opció lista átadása
   */
  @Input() options: IModalOptions;

  constructor(
    public modal: NgbActiveModal
  ) { }

  ngOnInit(): void {

    this.errorText += 'status: ' + this.payload.getErrorStatus() + "\n";
    this.errorText += 'requestId: ' + this.payload.getDebug().requestId + "\n";
    this.errorText += 'time: ' + this.payload.getDebug().time + "\n";
    this.errorText += 'env: ' + this.payload.getDebug().env + "\n";
    this.errorText += 'api: ' + this.payload.getDebug().api + "\n";
    this.errorText += 'uri: ' + this.payload.getDebug().uri + "\n";
    this.errorText += 'entity: ' + this.payload.getKind() + "\n";
    this.errorText += 'errors: ' + JSON.stringify(this.payload.errors) + "\n";

    this.errorArray = this.payload.getErrors();
  }

}
