import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { IEnvironmentAssets } from '../interfaces/environment.interfaces';

@Injectable({
    providedIn: 'root'
})
export class EnvironmentService {

    public static LAYOUT_GENERIC = 'generic';

    private _env = environment;

    getLayout(): any {
        return this._env.layout;
    }

    getAssets(): IEnvironmentAssets {
        return this._env.layout.assets[this._env.layout.version];
    }

    /**
     * Az elem csak egy specifikus verzió esetén látható (ebben az esetben a visszatérés true)
     * @param versions 
     * @returns 
     */
    showOnlySpecifiedVersions(versions: string): boolean {

        const vs = versions.split(',');

        if (vs) {
            return vs.includes(this._env.layout.version);            
        } else {
            // fallback, ha nem sikerülne vágni
            return (versions === this._env.layout.version)            
        }

    }

    /**
     * True ha csak a generic verzióban történik megjelenítés
     * @returns 
     */
    showOnlyGeneric(): boolean {
        return (this._env.layout.version === 'generic');
    }

    /**
     * Egy adott tag alapján előkeres egy verzió specifikus svg vagy png képet (url-t)
     * 
     * @param tag 
     * @returns 
     */
    getVersionImageByTag(tag: string): string {
        return this._env.layout.assets[this._env.layout.version].iconPaths.find(i => i.tag === tag).path;
    }

}
