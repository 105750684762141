<div class="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
    <!--begin::Signin-->
    <div class="login-form login-signin">
      <div class="text-center mb-10 mb-lg-20">
        <h3 class="font-size-h1">{{ 'label_change_password' | translate }}</h3>
        <p class="text-muted font-weight-bold">
          {{ 'label_change_password_help_text' | translate }}
        </p>
      </div>

      <kt-auth-notice></kt-auth-notice>
  
          <!--begin::Form-->
          <form class="form" [formGroup]="changePasswordForm" autocomplete="off" novalidate="novalidate" id="kt_change_password_form">
            <input autocomplete="false" name="hidden" type="text" style="display:none;">
            <div class="form-group">
      
              <label class="form-label">{{ 'form_label_email' | translate }} <span class="text-danger">*</span></label>
              <input type="text" class="form-control" placeholder="{{ 'form_label_email' | translate }}" formControlName="email" [value]="email" autocomplete="off" />
              <kt-form-control-error [control]="changePasswordForm.controls['email']" [options]="{ minLength: 5 }" [description]="'Kérjük adja meg az e-mail címét'"></kt-form-control-error>
      
            </div>

            <div class="row">
              <div class="form-group col-6">      
                <label class="form-label">{{ 'form_label_password' | translate }} <span class="text-danger">*</span></label>
                <input type="password" class="form-control" placeholder="{{ 'form_label_password' | translate }}" formControlName="password" autocomplete="new-password" />
                <kt-form-control-error [control]="changePasswordForm.controls['password']" [options]="{ minLength: 6 }" [description]="'Adjon meg egy jelszót'"></kt-form-control-error>        
              </div>
              <div class="form-group col-6">    
                <label class="form-label">{{ 'form_label_confirm_password' | translate }} <span class="text-danger">*</span></label>
                <input type="password" class="form-control" placeholder="{{ 'form_label_confirm_password' | translate }}" formControlName="confirmPassword" autocomplete="new-password" />
                <kt-form-control-error [control]="changePasswordForm.controls['confirmPassword']" [options]="{ minLength: 6 }" [description]="'Kérjük erősítse meg a beírt jelszót'"></kt-form-control-error>
              </div>
            </div>
        
            <!--begin::Action-->
            <div class="form-group d-flex flex-wrap justify-content-between align-items-center">
              <a href="javascript:;" routerLink="/auth/login" class="text-dark-50 text-hover-primary my-3 mr-2">{{ 'form_button_back_to_auth' | translate }}</a>
              <button type="button" (click)="submit($event)" [ngClass]="{
                  'spinner spinner-right spinner-md spinner-light': loading
                }" class="btn btn-primary font-weight-bold px-9 py-4 my-3" [disabled]="isSubmitButtonDisabled">{{ 'form_button_change_password' | translate }}</button>
            </div>
            <!--end::Action-->
  
          </form>
          <!--end::Form-->
  
    </div>
  </div>