import { IChatLoader } from "../shared/interfaces";

export class ChatLoader implements IChatLoader {

    allowed = false;
    
    source: string;
    
    refid: number;
    
    title?: string;

    /**
     * Notifikációhoz kell küldenünk (táska névre használju ha lehet)
     */
    jobname?: string;

    /**
     * Notifikációhoz kell küldenünk, ez lesz a tevékenyésg neve (mondjuk softproof cím, stb)
     */
    taskname?: string;

    /**
     * Táskaszám, ha van
     */
    taskaszam?: number;

    /**
     * Referencia kód (táska, softproof, stb)
     */
    kod: number;

    /**
     * Tab referencia, ha kell
     */
    tab: number;

    setTaskaSzam(id: number): ChatLoader {
        this.taskaszam = id;
        return this;
    } 

    getTaskaSzam(): number {
        return this.taskaszam;
    }

    setJobName(jobname: string): ChatLoader {
        this.jobname = jobname;
        return this;
    }

    getJobName(): string {
        return this.jobname;
    }

    setTaskName(taskname: string): ChatLoader {
        this.taskname = taskname;
        return this;
    }

    getTaskName(): string {
        return this.taskname;
    }

    setKod(kod: number): ChatLoader {
        this.kod = kod;
        return this;
    }

    getKod(): number {
        return this.kod;
    }

    setTab(tab: number): ChatLoader {
        this.tab = tab;
        return this;
    }

    getTab(): number {
        return this.tab;
    }
    
    allow(): ChatLoader {
        this.allowed = true;
        return this;
    }

    disallow(): ChatLoader {
        this.allowed = false;
        return this;
    }

    setSource(source: string): ChatLoader {
        this.source = source;
        return this;
    }

    setRefId(refid: number): ChatLoader {
        this.refid = refid;
        return this;
    }

    setTitle(title: string): ChatLoader {
        this.title = title;
        return this;
    }

    getSource(): string {
        return this.source;
    }

    getRefId(): number {
        return this.refid;
    }

    getTitle(): string {
        return this.title;
    }

    isAllowed(): boolean {
        return this.allowed;
    }
}