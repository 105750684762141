<span *ngIf="customError === '' && (control.invalid && (control.dirty || control.touched))" class="form-text text-danger">
    <span *ngIf="control.errors.required">
        {{ 'form_validator_field_mandatory' | translate }}
    </span>
    <span *ngIf="control.errors.minlength">
        {{ 'form_validator_field_min_length' | translate:{length: _options.minLength} }}
    </span>
    <span *ngIf="control.errors.maxlength">
        {{ 'form_validator_field_max_length' | translate:{length: _options.maxLength} }}
    </span>
</span>

<span *ngIf="customError !== ''" class="form-text text-danger">
    {{ customError | translate }}
</span>

<span class="form-text text-muted" *ngIf="customError === '' && ((control.invalid && (control.pristine && control.untouched)) || !control.invalid)">{{ description }}</span>