export interface IPermissionError {
    header: string;
    message: string;
}
  
export class PermissionError implements IPermissionError {
    header: string;
    message: string;
  
    setHeader(value: string): PermissionError { 
      this.header = value; 
      return this; 
    }

    setMessage(value: string): PermissionError {
        this.message = value;
        return this;
    }

    getHeader(): string {
        return this.header;
    }

    getMessage(): string {
        return this.message;
    }
}