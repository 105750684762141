import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Logout } from 'src/app/_core/auth';

@Component({
  selector: 'kt-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(private store: Store, private router: Router) { }

  ngOnInit(): void {
    this.store.dispatch(new Logout());
    // this.router.navigateByUrl('/auth/login');
  }

}
