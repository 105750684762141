import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, mergeMap, tap, withLatestFrom, map, switchMap, combineLatest, combineAll } from 'rxjs/operators';
import { defer, Observable, of } from 'rxjs';
import { AuthService } from '../../shared/services/auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';

/**
 * NGRX Store importok
 */
import { AppState } from '../../store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { AuthActionTypes, Login, Logout, Register, UserLoaded, UserRequested } from '../actions/auth.actions';
import { isUserLoaded, currentScopes } from '../selectors/auth.selectors';

/**
 * CoreLib importok
 */
import { environment } from '../../../../environments/environment';
import { EntityService, Entities, MetaArray } from '@ratkaiga/core';

/**
 * Minden AuthEffects forFeature-ként regisztrálódik az auth.module.ts-ben, ez garantálja nekünk, hogy az összes auth-al 
 * kapcsolatos állapotot egy önálló konténerben tudjuk tárolni a store-n belül.
 */
@Injectable()
export class AuthEffects {

  private static requiredScope: string;

  @Effect({ dispatch: false })
  login$ = this.actions$.pipe(
    ofType<Login>(AuthActionTypes.Login),
    tap(action => {

      // kiírjuk az access_tokent a local storage-be, majd lövünk egy új UserRequested actiont, ami betölti nekünk az adatokat
      localStorage.setItem(environment.localStorage.tokenStorage, action.payload.authToken);
      this.store.dispatch(new UserRequested());
    }),
  );

  @Effect({ dispatch: false })
  logout$ = this.actions$.pipe(
    ofType<Logout>(AuthActionTypes.Logout),
    tap(() => {

      // töröljük az access_tokent a local storageból
      localStorage.removeItem(environment.localStorage.tokenStorage);

      // a felhasználót elirányítjuk a login oldalra az aktuális returnUrl-el
      this.router.navigate(['/auth/login'], { queryParams: { returnUrl: this.returnUrl } });

      // fallback, ha a router nem irányítana át akkor újratöltjük az oldalt ami kikényszeríti az init effectet
      document.location.reload();
    })
  );

  @Effect({ dispatch: false })
  register$ = this.actions$.pipe(
    ofType<Register>(AuthActionTypes.Register),
    tap(action => {
      localStorage.setItem(environment.localStorage.tokenStorage, action.payload.authToken);
    })
  );

  @Effect({ dispatch: false })
  loadUser$ = this.actions$
    .pipe(
      ofType<UserRequested>(AuthActionTypes.UserRequested),
      withLatestFrom(this.store.pipe(select(isUserLoaded))),
      filter(([action, _isUserLoaded]) => !_isUserLoaded),
      mergeMap(([action, _isUserLoaded]) => this.auth.getUserByToken()),
      tap(_user => {

        const availableScopes: Entities.ScrollUserScope[] = [];
        const token = localStorage.getItem(environment.localStorage.tokenStorage);

        const helper = new JwtHelperService();
        const decodedToken = helper.decodeToken(token);
        const isExpired = helper.isTokenExpired(token);

        if (isExpired) {
          // ha a tokenünk expirált, akkor lövünk egy Logout actiont
          this.store.dispatch(new Logout());
        }

        // a scopeokat itt dolgozzuk fel, mert az érvényes token nem változhat. nincsen scope hozzáadás 
        // és elvétel, csak azzal megyünk a továbbiakban ami a tokenben van

        if (decodedToken.scope) {

          const _s = decodedToken.scope.split(' ');

          if (_s.length > 0) {
            _s.forEach(s => {
              availableScopes.push(new Entities.ScrollUserScope(s));
            });
          }
        }

        // ideiglenes hack, amíg nincsen új user profile végpont
        const _u = new Entities.ScrollUserProfileSimple();

        _u.id = _user['id'];
        // tslint:disable-next-line:no-string-literal
        _u.setFirstName(_user['firstname']);
        // tslint:disable-next-line:no-string-literal
        _u.setLastName(_user['lastname']);
        // tslint:disable-next-line:no-string-literal
        _u.setEmail(_user['email']);
        // tslint:disable-next-line:no-string-literal
        _u.setScopes(_user['scope']);


        if (_u) {
          this.store.dispatch(new UserLoaded({ user: _u, scopes: availableScopes }));
        } else {
          this.store.dispatch(new Logout());
        }

        // a getUserByToken által visszaadott response egy UserProfileEntity lesz amit az EntityService 
        // segítségével fogunk mappelni, majd lövünk egy UserLoaded actiont, ami betölti az user adatait.
        // Ha nem kapunk megfelelő responset a backend felől vagy az EntityService hibakezelője nem valid 
        // értéket tartalmaz, akkor lövünk egy Logout actiont.

        /*
        const _entityService = new EntityService();
        const _u = _entityService.map(_user).get();

        if (_u.getData() && _entityService.isValid() === true) {
          this.store.dispatch(new UserLoaded({ user: _u.getData(), scopes: availableScopes }));
        } else {
          this.store.dispatch(new Logout());
        } 
        */
      })
    );

  /**
   * Az összes effectet megelőzve ez fog legutni legelőször. A defer gondoskodik róla, hogy az observable 
   * gyakorlatilag egy promise legyen, ami gátolja, hogy async legyen a lekérdezés. Kivesszük a tokent, 
   * ha van és indítunk egy Login actiont. Ha nincs tokenünk, akkor emitelünk egy NO_ACTION-t.
   */
  @Effect()
  init$: Observable<Action> = defer(() => {
    const userToken = localStorage.getItem(environment.localStorage.tokenStorage);
    let observableResult = of({ type: 'NO_ACTION' });
    if (userToken) {
      observableResult = of(new Login({ authToken: userToken }));
    }
    return observableResult;
  });

  private returnUrl: string;

  constructor(private actions$: Actions, private router: Router, private auth: AuthService, private store: Store<AppState>) {

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.returnUrl = event.url;
      }
    });

  }
}
