import { AuthActions, AuthActionTypes } from '../actions/auth.actions';
import { Entities } from '@ratkaiga/core';

/**
 * AuthState interface deklarálása
 */
export interface AuthState {
  loggedIn: boolean;
  authToken: string;
  // user: Entities.UserProfileEntity;
  user: Entities.ScrollUserProfileSimple;
  scopes: Entities.ScrollUserScope[];
  isUserLoaded: boolean;
}

/**
 * Beállítunk egy fix initialAuthState változót, amely segítségével a store-t fel tudjuk tölteni
 * 
 * @see NGRX doc
 */
export const initialAuthState: AuthState = {
  loggedIn: false,
  authToken: undefined,
  user: undefined,
  scopes: undefined,
  isUserLoaded: false
};

export function authReducer(state = initialAuthState, action: AuthActions): AuthState {

  switch (action.type) {
    case AuthActionTypes.Login: {
      const token: string = action.payload.authToken;
      return {
        loggedIn: true,
        authToken: token,
        user: undefined,
        scopes: undefined,
        isUserLoaded: false
      };
    }

    case AuthActionTypes.Register: {
      const token: string = action.payload.authToken;
      return {
        loggedIn: true,
        authToken: token,
        user: undefined,
        scopes: undefined,
        isUserLoaded: false
      };
    }

    case AuthActionTypes.Logout:
      return initialAuthState;

    case AuthActionTypes.UserLoaded: {
      // const user: Entities.UserProfileEntity = action.payload.user;
      const user: Entities.ScrollUserProfileSimple = action.payload.user;
      const scopes: Entities.ScrollUserScope[] = action.payload.scopes;
      return {
        ...state,
        user,
        scopes,
        isUserLoaded: true
      };
    }

    default:
      return state;
  }
}
