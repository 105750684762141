import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { AuthService } from '../../../_core/shared/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthNoticeService } from 'src/app/_core/auth';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/_core/store';
import { CoreTranslateService } from '@ratkaiga/core/translate';
import { environment } from 'src/environments/environment';
import { CoreService, SanitizerService } from 'src/app/_core/shared/services';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {

  forgotPasswordForm: FormGroup;

	loading = false;

	errors: any = [];

	languages: { value: string, label: string }[] = [];

	isSubmitButtonDisabled = false;

	private unsubscribe: Subject<any>;

	private returnUrl: any;

	/**
	 * Component constructor
	 *
	 * @param router: Router
	 * @param auth: AuthService
	 * @param authNoticeService: AuthNoticeService
	 * @param translate: TranslateService
	 * @param store: Store<AppState>
	 * @param fb: FormBuilder
	 * @param cdr: ChangeDetectorRef
	 * @param route: ActivatedRoute
	 */
	constructor(
		private router: Router,
		private auth: AuthService,
		private authNoticeService: AuthNoticeService,
		private store: Store<AppState>,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private route: ActivatedRoute,
		private translateService: CoreTranslateService,
		private coreService: CoreService
	) {
		this.unsubscribe = new Subject();
	}

  ngOnInit(): void {

		// ha nincs még local storage bejegyzésünk az aktuális nyelvre akkor létrehozunk egyet
		if (!localStorage.getItem(environment.localStorage.translationStorage)) {
			localStorage.setItem(environment.localStorage.translationStorage, this.translateService.getCurrentLocale());
		}

		this.initLoginForm();

  }

  initLoginForm(): void {

    this.forgotPasswordForm = this.fb.group({
      email: [
        null,
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.minLength(3),
          Validators.maxLength(320), // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
        ]),
      ],
    });

  }

  ngOnDestroy(): void {
		this.authNoticeService.setNotice(null);
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}

  submit(): void {

	this.isSubmitButtonDisabled = true;

    if (this.forgotPasswordForm.valid) {
      
		let params = new HttpParams();
		params = params.set('email', SanitizerService.serialize(this.forgotPasswordForm.controls.email.value));
		params = params.set('nyomda_id', environment.client_id.toString());

		this.coreService.forgotPassword(params).subscribe(() => {
			this.authNoticeService.setNotice('Köszönjük, az információkat e-mailben elküldtük', 'success');
		}, () => {
			this.authNoticeService.setNotice('Az e-mail cím nem található meg a rendszerben', 'danger');

			this.forgotPasswordForm.reset();
			this.isSubmitButtonDisabled = false;
		});

    } else {
    
		this.authNoticeService.setNotice('Kérjük, hogy valós e-mail címet adjon meg');

		this.forgotPasswordForm.reset();
		this.isSubmitButtonDisabled = false;
    }

  }
}
