import { Component, ChangeDetectionStrategy, OnDestroy, OnInit } from '@angular/core';
import { SplashScreenService } from './_metronic/partials/layout/splash-screen/splash-screen.service';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

import { Title } from '@angular/platform-browser';
import { LayoutService } from './_metronic/core';
import { EventBusService } from './_core/shared/services/event-bus.service';
import { EventBusMetaData } from './_core/models/event-bus.meta';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {

  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

  constructor(
    private eventBusService: EventBusService, 
    private layoutConfigService: LayoutService, 
    private splashScreenService: SplashScreenService, 
    private router: Router, 
    private titleService: Title) {
  }

  ngOnInit(): void {

    this.titleService.setTitle(this.layoutConfigService.getProp('page-title.default'));

    const routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // hide splash screen
        this.splashScreenService.hide();

        // scroll to top on every route change
        window.scrollTo(0, 0);

        // to display back the body content
        setTimeout(() => {
          document.body.classList.add('page-loaded');
        }, 500);
      }
    });

    this.eventBusService.on('callas:preflight-xml-completed').subscribe((meta: EventBusMetaData) => {
      console.log(meta.data); // will receive 'Bye!'
    });

    this.eventBusService.on('callas:preflight-pdf-completed').subscribe((meta: EventBusMetaData) => {
      console.log(meta.data); // will receive 'Bye!'
    });

    this.unsubscribe.push(routerSubscription);
  }

  ngOnDestroy(): void {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }


}
