/* eslint-disable no-prototype-builtins */
import { Component, ComponentFactory, ComponentFactoryResolver, ComponentRef, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, forkJoin, Observable, of, ReplaySubject, Subject, throwError, zip } from 'rxjs';
import { MetaArray, EntityService, Entities, CoreItemEntity, CoreResponse, CoreResponseError, CoreRequestParams, CoreRequestFilterModel } from '@ratkaiga/core';
import { environment } from 'src/environments/environment';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../../store';
import Dexie from 'dexie';
import { IChatLoader, IModalOptions, ModalOptions } from '../interfaces';
import { IPermissionError } from '../../entities/printportal-error.entity';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrorModalComponent } from '../../../pages/_layout/partials/error-modal/error-modal.component';
import { EventBusService } from './event-bus.service';
import { EventBusMetaData } from '../../models/event-bus.meta';
import { ChatLoader } from '../../models/chat';
import { PrintportalUploadProgress } from '../../entities/upload-progress.entity';
import { PreloadEntity } from '../../entities/preload.entity';

interface IErrorLogger {
  id?: number;
  status?: number;
  requestTime?: string;
  requestId?: string;
  api?: string;
  env?: string;
  uri?: string;
  errors?: string;
  dateTime?: string;
}

class ErrorLoggerDatabase extends Dexie {

  public logTable: Dexie.Table<IErrorLogger, number>;

  public constructor() {

    super("ErrorLoggerDatabase");

    this.version(1).stores({
      logs: "++id,status,requestTime,requestId,api,env,uri,errors,dateTime"
    });

    this.logTable = this.table("logs");
  }
}

export class ProfileWidgetInfo {
  symbol: string;
  name: string;
  email: string; 
  nyomda: string;
}

@Injectable({
  providedIn: 'root'
})
export class CoreService {

  private _validFileTypes: string[] = [
    'image/jpg', 'image/jpeg', 'image/png', 'application/pdf'
  ]

  /**
   * IndexedDB instance-t fogjuk tárolni
   */
  private db;

  /**
   * @var Entities.UserProfileSimpleEntity
   */
  currentUser: Entities.ScrollUserProfileSimple;

  /**
   * Ez a változó fogja tartalmazni az aktuálisan kiválasztott nyomdát
   * 
   * @var ReplaySubject
   */
  currentNyomda: ReplaySubject<Entities.ScrollNyomda> = new ReplaySubject(1);

  /**
   * @var BehaviorSubject
   */
  private nyomdaSubject: BehaviorSubject<MetaArray<Entities.ScrollNyomda>> = new BehaviorSubject(new MetaArray());

  /**
   * @var Observable
   */
  nyomda = this.nyomdaSubject.asObservable();

  /**
   * @var BehaviorSubject
   */
  private partnerListSubject: BehaviorSubject<CoreResponse> = new BehaviorSubject(undefined);

  /**
   * @var Observable
   */
  partnerList = this.partnerListSubject.asObservable();

  chatToggle = false;

  activeChatSubject: Subject<IChatLoader> = new Subject();
  notifyChatUser: Subject<boolean> = new Subject();
  stopChatPooling: Subject<void> = new Subject();

  uploadActive: Subject<boolean> = new Subject();
  uploadItems: BehaviorSubject<PrintportalUploadProgress[]> = new BehaviorSubject([]);
  currentUploadItems = this.uploadItems.asObservable();

  /**
   * Ebben a subjectben tároljuk az utoljára megnyitott ajánlatot
   * 
   * @var ReplaySubject
   */
  currentOffer: ReplaySubject<Entities.ScrollPrintportalSpLevel> = new ReplaySubject(1);
  currentCallasPreflight: ReplaySubject<Entities.ScrollPrintportalSpTaskaFile> = new ReplaySubject(1);

  prefightXMLListSubject: BehaviorSubject<unknown[]> = new BehaviorSubject([]);

  currentLoadedKerdesek: BehaviorSubject<Entities.ScrollPrintportalLevelKerdes[]> = new BehaviorSubject([]);
  currentLoadedNevek: BehaviorSubject<Entities.ScrollPrintportalNev[]> = new BehaviorSubject([]);
  currentLoadedPartcimek: BehaviorSubject<Entities.ScrollPrintportalPartnerCim[]> = new BehaviorSubject([]);
  currentLoadedTermekdijak: BehaviorSubject<Entities.ScrollPrintportalTermekdij[]> = new BehaviorSubject([]);
  currentLoadedTaskaLista: BehaviorSubject<Entities.ScrollPrintportalSpTaska[]> = new BehaviorSubject([]);

  private profile: ProfileWidgetInfo;
  public currentProfile: ReplaySubject<ProfileWidgetInfo> = new ReplaySubject(1);
  
  public taskaListaTipusfilterSubject: ReplaySubject<string | undefined> = new ReplaySubject(1);

  public getCurrentLoadedNevek = this.currentLoadedNevek.getValue();

  preloadEntity: PreloadEntity = new PreloadEntity();
  preloadSubject: BehaviorSubject<PreloadEntity> = new BehaviorSubject(null);

  /**
    * Entitások előtöltésekre kerültek -e
    */
  protected preloaded = false;

  /**
   * Az utolsó olyan hibát próbáljuk meg itt eltárolni ami elég releváns ahhoz, hogy megjelenítsük az oldalon.
   */
  lastError: Subject<IPermissionError> = new Subject();

  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
    private componentFactoryResolver: ComponentFactoryResolver,
    private eventBusService: EventBusService
  ) {

    this.db = new ErrorLoggerDatabase();

    this.currentNyomda.next(new Entities.ScrollNyomda());

    this.store.subscribe(result => {
      // eslint-disable-next-line no-prototype-builtins
      if (result.hasOwnProperty('auth')) {
        // tslint:disable-next-line:no-string-literal
        this.currentUser = result['auth'].user;
      }
    });

    this.activeChatSubject.next(new ChatLoader());
    this.uploadActive.next(false);

  }

  /**
   * @param value 
   */
  setPreloaded(value: boolean): void {
    this.preloaded = value;
  }

  /**
   * Megmondja nekünk, hogy előtöltöttük-e a preload entitást
   * @returns 
   */
  isPreloaded(): boolean {
    return this.preloaded;
  }

  /**
   * Seteljük a preload entitásunkat. Ez egy csomó olyan dolgot tartalmazhat amit csak egyszer, illetve 
   * előre még a felület felépítése előtt szükséges betöltenünk. Azokat az entitás szetteket, amiket nem 
   * csak egyszer töltünk be, már itt a besetelés során próbáljuk meg kezelni. 
   * 
   * @param value 
   */
  setPreloadEntity(value: PreloadEntity): void {

    // preload entitás setelése
    this.preloadEntity = value;
    // preload entiás setelése behavior subjectbe is
    this.preloadSubject.next(value);
 
  }

  /**
   * Bármikor meghívható metódus a preload entitások frissítéséhez. Ilyenre simán szükségünk lehet, hiszen a 
   * felhasználó válthat nyomdát pl.
   */
  fetchPreloadEntity(): void {
    this.http.get(`${environment.endpoints.app}/pp_preload_v2/${this.currentNyomdaId()}`).pipe(map((r) => { 
      this.setPreloadEntity(r['data'].payload as PreloadEntity);
    })).subscribe();
  }

  public createUploadProgress(name: string): void {

    const up = new PrintportalUploadProgress();
    up.name = name;
    up.progress = 0;
    
    const values = this.uploadItems.getValue();
    values.push(up)
    
    this.uploadActive.next(true);
    this.uploadItems.next(values);    
  }

  public updateUploadProgress(name: string, progress: number): void {
    // lekérjük a behaviorsubject értékeit
    const values = this.uploadItems.getValue();
    
    // felülírjuk a progress értéket
    if (values.find(p => p.name === name) instanceof PrintportalUploadProgress) {
      values.find(p => p.name === name).progress = progress;
    }    

    if (progress === 100) {
      values.splice(values.findIndex(p => p.name === name));
    }

    if (values.length === 0) {
      this.uploadActive.next(false);
    }

    // seteljük a behaviorsubjectnek
    this.uploadItems.next(values);
  }

  public validateUploadFile(type: string, custom?: string[]): boolean {

    if (custom) {
      if (custom.includes(type)) {
        return true;
      }

      return false;
    }

    if (this._validFileTypes.includes(type)) {
      return true;
    }

    return false;
  }

  public updateProfileWidget(): void {
    this.fetchUserInfo().subscribe(r => {

      this.profile = new ProfileWidgetInfo();
      this.profile.symbol = String(r[0].firstname.charAt(0) + r[0].lastname.charAt(0)).toUpperCase();
      this.profile.name = String(r[0].firstname + ' ' +  r[0].lastname);
      this.profile.email = r[0].email;
      this.profile.nyomda = 'Nyomda betöltése...';

      this.nyomda.subscribe(n => {        
        this.profile.nyomda = n.getData().find(nx => nx.id === parseInt(r[1]))?.nev;        
        this.currentProfile.next(this.profile);
      });

    });
  }

  forgotPassword(params: HttpParams): Observable<CoreResponse> {
    
    // let headers = new HttpHeaders();
    // headers = headers.set('Content-Type', 'multipart/form-data');

    return this.http.post(`${environment.endpoints.app}/nyomdauser/forgot`, params).pipe(
      map(baseResult => new EntityService().map(baseResult).get()),
      catchError((errorResult) => {
        // re-map
        const err: CoreResponseError = new EntityService().mapError(errorResult);
        // logolás
        this.logError(err);
        // re-throw
        return throwError(err);
      })
    );
  }

  changePassword(params: HttpParams): Observable<CoreItemEntity> {

    return this.http.post(`${environment.endpoints.app}/nyomdauser/password`, params).pipe(
      map(baseResult => new EntityService().map(baseResult).get()),
      catchError((errorResult) => {
        // re-map
        const err: CoreResponseError = new EntityService().mapError(errorResult);
        // logolás
        this.logError(err);
        // re-throw
        return throwError(err);
      })
    );

  }

  changePasswordFromPopup(params: HttpParams): Observable<any> {
    return this.http.post(`${environment.endpoints.app}/auth/password-change/printportal`, params);
  }

  fetchUserInfo(): Observable<any> {
    return forkJoin([
      this.http.get(`${environment.endpoints.auth}/auth/info`), 
      of(this.currentNyomdaId())
    ]);
  }

  /**
   * Az aktív nyomda azonosítójának meghatározása a localSatorage alapján (ez a nyomdaválasztás megtartásához szükséges)
   */
  currentNyomdaId(): number {
    return parseInt(localStorage.getItem(environment.localStorage.partnerStorage), 0);
  }

  /**
   * Nyomdák listájának betöltése. A filters a szabványos core filterezés string formátumú kimenete.
   * 
   * @param filters 
   */
  getNyomda(id: number): Observable<CoreItemEntity> {
    return this.http.get(`${environment.endpoints.app}/nyomda/${id}`).pipe(map(baseResult => new EntityService().map(baseResult).get()));
  }

  /**
   * Nyomdák listájának betöltése. A filters a szabványos core filterezés string formátumú kimenete.
   * 
   * @param filters 
   */
  fetchNyomda(filters = ''): void {
    this.http.get(`${environment.endpoints.app}/nyomda${filters}`).pipe(map(baseResult => new EntityService().map(baseResult).get())).subscribe(results => {
      this.nyomdaSubject.next(results.getData());
    });
  }

  /**
   * A metódusunk egy CoreResponse objektummal tér vissza. Arra használjuk, hogy az adott nyomdához 
   * kapcsolódó neveket kérdezzük le vagy azokban keressünk. A filters egy szabványos core filter stringet vár. 
   * 
   * @param filters
   * @param path 
   * @returns 
   */
  observeChatMessages(type: string, kod: number): Observable<CoreResponse> {
    return this.http.get(`${environment.endpoints.app}/chat/${this.currentNyomdaId()}/${type}/${kod}`).pipe(map(baseResult => new EntityService().map(baseResult).get()));
  }

  /**
   * 
   * @param type 
   * @param message 
   * @returns 
   */
  sendChatMessage(loader: ChatLoader, message: string): Observable<CoreResponse> {

    let params = new HttpParams();
    params = params.set('type', loader.getSource());    
    params = params.set('kod', loader.getRefId().toString());
    params = params.set('taska', loader.getTaskaSzam().toString());
    params = params.set('tab', loader.getTab().toString());
    params = params.set('jobname', loader.getJobName());
    params = params.set('message', message);

    if (loader.getTaskaSzam())  {
      params = params.set('taskaszam', loader.getTaskaSzam().toString());
    }

    if (loader.getTaskName()) {
      params = params.set('taskname', loader.getTaskName());
    }
    
    return this.http.post(`${environment.endpoints.app}/chat/${this.currentNyomdaId()}/`, params).pipe(map(baseResult => new EntityService().map(baseResult).get()));
  }


  /**
   * Kiküldnünk egy levelet
   */
  reorderOffer(level: number, version: number): Observable<unknown> {

    let params = new HttpParams();
    params = params.set('level', level.toString());
    params = params.set('version', version.toString());
    params = params.set('email', environment.layout.controlConfigs.reorderNotificationEmail);

    return this.http.post(`${environment.endpoints.app}/pp_rendeles/${this.currentNyomdaId()}/reorder`, params);
  }


  /**
   * Egy adott nyomda partnereit kérjük le. Az eredmény CoreResponse lesz amit átadunk a behavior subjectnek.
   * 
   * @param nyomda 
   * @param filters 
   */
  fetchPartnerList(nyomda: number, filters = ''): void {
    this.http.get(`${environment.endpoints.app}/partner/${nyomda}${filters}`).pipe(map(baseResult => new EntityService().map(baseResult).get())).subscribe(results => {
      this.partnerListSubject.next(results);
    });
  }

  /**
   * A funkció egy obszerver, amely feliratkozás esetén egy adott nyomdához tartozó partner kód alapján kéri le a partner összes adatát a backend felől.
   * 
   * @param nyomda number
   * @param partner number
   * @param filters string
   * 
   * @returns Observable<BaseItemEntity>
   */
  fetchPartnerDetails(nyomda: number, partner: number, filters = ''): Observable<CoreItemEntity> {
    return this.http.get(`${environment.endpoints.app}/partner/${nyomda}/${partner}${filters}`).pipe(map(baseResult => new EntityService().map(baseResult).get()));
  }

  /**
   * 
   * @param nyomda 
   * @returns 
   */
  fetchJobStats(nyomda: number): Observable<CoreItemEntity> {
    return this.http.get(`${environment.endpoints.app}/stat/${nyomda}/taska`).pipe(map(baseResult => new EntityService().map(baseResult).get()));
  }

  /**
   * A metódus egy adott filter szerint lekérdezi a táskalistát
   * 
   * @param filters 
   * @returns 
   */
  observeTaskaList(filters = ''): Observable<CoreResponse> {
    return this.http.get(`${environment.endpoints.app}/taska-v2/${this.currentNyomdaId()}${filters}`)
    .pipe(
      map(baseResult => new EntityService().map(baseResult).get()),
      catchError((errorResult) => {
        // re-map
        const err: CoreResponseError = new EntityService().mapError(errorResult);
        // logolás
        this.logError(err);
        // re-throw
        return throwError(err);
      })
    );
  }

  /**
   * A metódus egy adott softproofot kér le a kiszolgálóról
   * 
   * @param filters 
   * @returns 
   */
   observeSoftproof(id: number): Observable<CoreResponse> {
    return this.http.get(`${environment.endpoints.app}/softproof/${this.currentNyomdaId()}/${id.toString()}`)
    .pipe(
      map(baseResult => new EntityService().map(baseResult).get()),
      catchError((errorResult) => {
        // re-map
        const err: CoreResponseError = new EntityService().mapError(errorResult);
        // logolás
        this.logError(err);
        // re-throw
        return throwError(err);
      })
    );
  }  

  /**
   * 
   * @param params 
   * @returns 
   */
  setSoftproofStatus(params: HttpParams): Observable<CoreResponse> {
    return this.http.post(`${environment.endpoints.app}/softproof/${this.currentNyomdaId()}/status`, params)
    .pipe(
      map(baseResult => new EntityService().map(baseResult).get()),
      catchError((errorResult) => {
        // re-map
        const err: CoreResponseError = new EntityService().mapError(errorResult);
        // logolás
        this.logError(err);
        // re-throw
        return throwError(err);
      })
    );
  }

  acceptSoftproof(proofid: number, reason: string): Observable<any> {

    let params = new HttpParams();
    params = params.set('id', proofid.toString());
    params = params.set('reason', reason);

    return this.http.post(`${environment.endpoints.app}/softproof/${this.currentNyomdaId()}/accept`, params)
    .pipe(
      map(baseResult => new EntityService().map(baseResult).get()),
      catchError((errorResult) => {
        // re-map
        const err: CoreResponseError = new EntityService().mapError(errorResult);
        // logolás
        this.logError(err);
        // re-throw
        return throwError(err);
      })
    );
  }

  declineSoftrpoof(proofid: number, reason: string): Observable<any> {

    let params = new HttpParams();
    params = params.set('id', proofid.toString());
    params = params.set('reason', reason);

    return this.http.post(`${environment.endpoints.app}/softproof/${this.currentNyomdaId()}/decline`, params)
    .pipe(
      map(baseResult => new EntityService().map(baseResult).get()),
      catchError((errorResult) => {
        // re-map
        const err: CoreResponseError = new EntityService().mapError(errorResult);
        // logolás
        this.logError(err);
        // re-throw
        return throwError(err);
      })
    );

  }


  /**
   * A metódus egy adott filter szerint lekérdezi a táskalistát
   * 
   * @param filters 
   * @returns 
   */
  observeTaskaDetails(taskaszam: number): Observable<CoreResponse> {
      return this.http.get(`${environment.endpoints.app}/taska-details/${this.currentNyomdaId()}/${taskaszam.toString()}`)
      .pipe(
        map(baseResult => new EntityService().map(baseResult).get()),
        catchError((errorResult) => {
          // re-map
          const err: CoreResponseError = new EntityService().mapError(errorResult);
          // logolás
          this.logError(err);
          // re-throw
          return throwError(err);
        })
      );
    }

  /**
   * A metódus egy adott táskaszám alapján lekérdezi a táskához elérhető állományokat. 
   * 
   * @param taskaId
   * @param filters 
   * @returns 
   */
  observeTaskaFileList(taskaId: number, filters = ''): Observable<CoreResponse> {
    return this.http.get(`${environment.endpoints.app}/taska-files/${this.currentNyomdaId()}/by-taska/${taskaId}${filters}`)
    .pipe(
      map(baseResult => new EntityService().map(baseResult).get()),
      catchError((errorResult) => {
        // re-map
        const err: CoreResponseError = new EntityService().mapError(errorResult);
        // logolás
        this.logError(err);
        // re-throw
        return throwError(err);
      })
    );
  }

  uploadTaskaFile(params: FormData): Observable<any> {
    return this.http.post(`${environment.endpoints.app}/taska-files/${this.currentNyomdaId()}/upload`, params, {
      reportProgress: true,
      observe: 'events'
    });
  }

  downloadTaskaFile(hash: string): Observable<Blob> {
    return this.http.get(`${environment.endpoints.app}/taska-files/${this.currentNyomdaId()}/download/${hash}`, 
    { responseType: 'blob' });
  }

  startPreflightXML(hash: string): void {

    this.http.get(`${environment.endpoints.callas}/pdftoolbox/preflight/xml/${hash}`).subscribe((r) => {
      const data = new EventBusMetaData('callas:preflight-xml-completed', r);
      this.eventBusService.cast('callas:preflight-xml-completed', data);
    });
    
  }

  downloadSzamlaFile(kod: number): Observable<Blob> {
    return this.http.get(`${environment.endpoints.app}/szamla/${this.currentNyomdaId()}/${kod.toString()}`, 
    { responseType: 'blob' });
  }

  downloadDriveFile(hash: string): Observable<Blob> {
    return this.http.get(`${environment.endpoints.fileservice}/guest/download/${hash}`, 
    { responseType: 'blob' });
  }

  /**
   * Létrehozunk a backenden keresztül egy nevet az aktuális partnerhez, a path változó 
   * értéke alapértelmezésben üres, amennyiben a nevet a megrendelésből hozzuk létre, akkor 
   * a változónak meg kell adnunk az "/ajanlat-nevek" útvonalat.
   * 
   * @param params 
   * @param path 
   * @returns 
   */
   createMegrendelesDefaults(params: HttpParams, path = ''): Observable<CoreResponse> {    
    return this.http.post(`${environment.endpoints.app}/pp_rendeles/${this.currentNyomdaId()}/setdefaults`, params)
      .pipe(
        map(baseResult => new EntityService().map(baseResult).get()),
        catchError((errorResult) => {
          // re-map
          const err: CoreResponseError = new EntityService().mapError(errorResult);
          // logolás
          this.logError(err);
          // re-throw
          return throwError(err);
        })
      );
  }

  /**
   * A metódusunk egy CoreResponse objektummal tér vissza. Arra használjuk, hogy az adott nyomdához 
   * kapcsolódó neveket kérdezzük le vagy azokban keressünk. A filters egy szabványos core filter stringet vár. 
   * 
   * @param filters
   * @param path 
   * @returns 
   */
  observeNevList(filters = '', path = ''): Observable<CoreResponse> {
    return this.http.get(`${environment.endpoints.app}/nev/${this.currentNyomdaId()}/${path}${filters}`).pipe(map(baseResult => new EntityService().map(baseResult).get()));
  }

  /**
   * Proxy metódus az observeNevList gyorsabb eléréséhez, ha csak a név kódja áll rendelkezésünkre.
   * 
   * @param kod 
   * @returns 
   */
  observeNev(kod: number): Observable<CoreResponse> {

    const params: CoreRequestParams = new CoreRequestParams([
      new CoreRequestFilterModel('kod', kod.toString())
    ]);

    return this.observeNevList(params.getQuery());
  }

  /**
   * Töröltre állítunk a backenden keresztül egy nevet az aktuális partnerhez, a path változó 
   * értéke alapértelmezésben üres, amennyiben a nevet a megrendelésből töröljük, akkor 
   * a változónak meg kell adnunk az "/ajanlat-nevek" útvonalat.
   * 
   * @param kod 
   * @param params 
   * @param path 
   * @returns 
   */
   deleteNev(kod: number, partner: number, path = ''): Observable<CoreResponse> {
    return this.http.delete(`${environment.endpoints.app}/nev/${this.currentNyomdaId()}/${partner}/${path}${kod}`)
    .pipe(
      map(baseResult => new EntityService().map(baseResult).get()),
      catchError((errorResult) => {
        // re-map
        const err: CoreResponseError = new EntityService().mapError(errorResult);
        // logolás
        this.logError(err);
        // re-throw
        return throwError(err);
      })
    );
  }

  /**
   * Megváltoztatunk a backenden keresztül egy nevet az aktuális partnerhez, a path változó 
   * értéke alapértelmezésben üres, amennyiben a nevet a megrendelésből hozzuk létre, akkor 
   * a változónak meg kell adnunk az "/ajanlat-nevek" útvonalat.
   * 
   * @param kod 
   * @param params 
   * @param path 
   * @returns 
   */
  changeNev(kod: number, params: HttpParams, path = ''): Observable<CoreResponse> {
    return this.http.put(`${environment.endpoints.app}/nev/${this.currentNyomdaId()}/${path}${kod}`, params)
    .pipe(
      map(baseResult => new EntityService().map(baseResult).get()),
      catchError((errorResult) => {
        // re-map
        const err: CoreResponseError = new EntityService().mapError(errorResult);
        // logolás
        this.logError(err);
        // re-throw
        return throwError(err);
      })
    );
  }

  /**
   * Létrehozunk a backenden keresztül egy nevet az aktuális partnerhez, a path változó 
   * értéke alapértelmezésben üres, amennyiben a nevet a megrendelésből hozzuk létre, akkor 
   * a változónak meg kell adnunk az "/ajanlat-nevek" útvonalat.
   * 
   * @param params 
   * @param path 
   * @returns 
   */
  createNev(params: HttpParams, path = ''): Observable<CoreResponse> {    
    return this.http.post(`${environment.endpoints.app}/nev/${this.currentNyomdaId()}${path}`, params)
      .pipe(
        map(baseResult => new EntityService().map(baseResult).get()),
        catchError((errorResult) => {
          // re-map
          const err: CoreResponseError = new EntityService().mapError(errorResult);
          // logolás
          this.logError(err);
          // re-throw
          return throwError(err);
        })
      );
  }

  /**
   * A metódusunk egy CoreResponse objektummal tér vissza. Arra használjuk, hogy az adott nyomdához 
   * kapcsolódó neveket kérdezzük le vagy azokban keressünk. A filters egy szabványos core filter stringet vár. 
   * 
   * @param filters 
   * @returns 
   */
  observePartnerCimList(filters = '', path = ''): Observable<CoreResponse> {
    return this.http.get(`${environment.endpoints.app}/partcimek/${this.currentNyomdaId()}/${path}${filters}`)
    .pipe(
      map(baseResult => new EntityService().map(baseResult).get()),
      catchError((errorResult) => {
        // re-map
        const err: CoreResponseError = new EntityService().mapError(errorResult);
        // logolás
        this.logError(err);
        // re-throw
        return throwError(err);
      })
    );
  }

  /**
   * Partner cím bejegyzés megváltoztatása
   * 
   * @param kod 
   * @param params 
   * @returns 
   */
  changePartnerCim(kod: number, params: HttpParams, path = ''): Observable<CoreResponse> {
    return this.http.put(`${environment.endpoints.app}/partcimek/${this.currentNyomdaId()}/${path}${kod}`, params)
    .pipe(
      map(baseResult => new EntityService().map(baseResult).get()),
      catchError((errorResult) => {
        // re-map
        const err: CoreResponseError = new EntityService().mapError(errorResult);
        // logolás
        this.logError(err);
        // re-throw
        return throwError(err);
      })
    );
  }

  /**
   * Partner cím létrehozása
   * 
   * @param params 
   * @returns 
   */
  createPartnerCim(params: HttpParams, path = ''): Observable<CoreResponse> {
    return this.http.post(`${environment.endpoints.app}/partcimek/${this.currentNyomdaId()}${path}`, params)
      .pipe(
        map(baseResult => new EntityService().map(baseResult).get()),
        catchError((errorResult) => {
          // re-map
          const err: CoreResponseError = new EntityService().mapError(errorResult);
          // logolás
          this.logError(err);
          // re-throw
          return throwError(err);
        })
      );
  }

  /**
   * Partner cím törlése
   *  
   * @param cim 
   * @param partner 
   * @returns 
   */
  deletePartnerCim(cim: number, partner: number): Observable<CoreResponse> {
    return this.http.delete(`${environment.endpoints.app}/partcimek/${this.currentNyomdaId()}/${partner}/${cim}`)
    .pipe(
      map(baseResult => new EntityService().map(baseResult).get()),
      catchError((errorResult) => {
        // re-map
        const err: CoreResponseError = new EntityService().mapError(errorResult);
        // logolás
        this.logError(err);
        // re-throw
        return throwError(err);
      })
    );
  }

  observeKerdesek(filters = ''): Observable<CoreResponse> {
    return this.http.get(`${environment.endpoints.app}/levelkerdes-v2/${this.currentNyomdaId()}/${filters}`).pipe(map(r => new EntityService().map(r).get()));
  }

  /**
   * A metódusunk egy CoreResponse objektummal tér vissza. Arra használjuk, hogy az adott nyomdához 
   * kapcsolódó termékdíjakat kérdezzük le vagy azokban keressünk. A filters egy szabványos core filter stringet vár. 
   * 
   * @param filters 
   * @returns 
   */
  observeTermekdijList(filters = ''): Observable<CoreResponse> {
    return this.http.get(`${environment.endpoints.app}/termekdij/${this.currentNyomdaId()}/${filters}`).pipe(map(baseResult => new EntityService().map(baseResult).get()));
  }

  /**
   * A metódusunk egy CoreResponse objektummal tér vissza. Arra használjuk, hogy az adott nyomdához 
   * kapcsolódó partnerek címeit lekérdezzük. A filters egy szabványos core filter stringet vár. Pontosítás
   * itt a beléptetett userhez tartozó partnercímek lesznek (amelyet természetesen nyomdához is kapcsolódnak)
   * 
   * @param filters 
   * @returns 
   */
  observePartnerAdressList(filters = ''): Observable<CoreResponse> {
    return this.http.get(`${environment.endpoints.app}/partcimek/${this.currentNyomdaId()}/${filters}`).pipe(map(baseResult => new EntityService().map(baseResult).get()));
  }

  /**
   * Hiba naplózása az indexedDB-be
   * 
   * @param err 
   */
  logError(err: CoreResponseError): void {

    this.db.transaction('rw', this.db.logTable, async () => {

      const log: IErrorLogger = {
        status: err.getErrorStatus(),
        requestTime: err.getDebug().time,
        requestId: err.getDebug().requestId,
        api: err.getDebug().api,
        env: err.getDebug().env,
        uri: err.getDebug().uri,
        errors: JSON.stringify(err.getErrors()),
        dateTime: new Date().toString()
      };

      this.db.logTable.add(log);

    }).catch(e => {
      console.log('[PP Core Logger Error]', e.stack || e);
    });

  }

  setResourceError(error: IPermissionError): void {
    this.lastError.next(error);    
  }

  populateErrorMessage(err: CoreResponseError, modal: NgbModal): void {

    const options: IModalOptions = new ModalOptions();
    options.title = 'Hiba';

    // deklaráljuk a modal referenciát 

    const modalRef = modal.open(ErrorModalComponent, { size: 'xl' });
    modalRef.componentInstance.options = options;
    modalRef.componentInstance.payload = err;

  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/ban-types
  deepCopy(o: object) {
    
    // taken from https://jsperf.com/deep-copy-vs-json-stringify-json-parse/5
    let newO, i;
  
    if (typeof o !== 'object') {
      return o;
    }
    if (!o) {
      return o;
    }
  
    if ('[object Array]' === Object.prototype.toString.apply(o)) {
      newO = [];
      for (i = 0; i < (o as Array<unknown>).length; i += 1) {
        newO[i] = this.deepCopy(o[i]);
      }
      return newO;
    }
  
    newO = {};
    for (i in o) {
      if (o.hasOwnProperty(i)) {
        newO[i] = this.deepCopy(o[i]);
      }
    }
    return newO;
  }

}
