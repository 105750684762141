import { createSelector } from '@ngrx/store';
import { each, find, some } from 'lodash';
import { Entities } from '@ratkaiga/core';

export const selectAuthState = state => state.auth;

export const isLoggedIn = createSelector(selectAuthState, auth => auth.loggedIn);

export const isLoggedOut = createSelector(isLoggedIn, loggedIn => !loggedIn);

export const currentAuthToken = createSelector(selectAuthState, auth => auth.authToken);

export const isUserLoaded = createSelector(selectAuthState, auth => auth.isUserLoaded);

export const currentUser = createSelector(selectAuthState, auth => auth.user);

export const currentScopes = createSelector(selectAuthState, auth => auth.scopes);

export const checkHasUserPermission = (scope: string) => createSelector(
  currentScopes,
  (scopes: Entities.ScrollUserScope[]) => {
    return; // scopes.some(scope => scope === permissionId);
  }
);
