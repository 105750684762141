export interface ITablePaginatorOptions {
    allowButtons?: {
        first?: boolean;
        last?: boolean;
        next?: boolean;
        previous?: boolean;
    },
    allowDots?: {
        use?: boolean;
        treeshold?: number;
    },
    allowedItemCounts?: number[],
    maxPage?: number;
}

export class TablePaginatorOptions implements ITablePaginatorOptions {

    allowButtons = {
        first: true,
        last: true,
        next: true,
        previous: true
    };

    allowDots = {
        use: false,
        treeshold: 5
    };

    allowedItemCounts = [5, 10, 25];

    maxPage = 1;

    pagerButtons: { index: number, label: number, dot?: boolean }[] = [];

    pagerCurrentPage = 0;

    // workaround
    pagerItemsPerPage = 25;
}