import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { Store } from '@ngrx/store';
import { AppState } from '../../store';
import { Logout } from '../../auth';
import { CoreService } from './core.service';
import { PermissionError } from '../../entities/printportal-error.entity';

/**
 * HttpRequest 
 * 
 * Elkapjuk az összes HttpRequest-et és ahol nem 200 jön vissza ott hibakezelést végzünk. A 401-as errorok esetén 
 * kiléptetjük a felhasználót, mert feltehetőleg lejárt a tokenje, 403 esetén resource probléma van
 * 
 * @see https://medium.com/@MetonymyQT/angular-http-interceptors-what-are-they-and-how-to-use-them-52e060321088
 */
@Injectable()
export class InterceptService implements HttpInterceptor {

  constructor(public store: Store<AppState>, private coreService: CoreService) { }

  // elkapjuk a requestet (bármelyiket) és hozzácsapjuk a token-t
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (!request.url.indexOf('auth/login') && localStorage.getItem(environment.localStorage.tokenStorage).length > 0) {

      // az angular esetében, hasonlóan a symfonyhoz a requestet clone segítségével lehet módosítani, mert alapból inmutable
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${localStorage.getItem(environment.localStorage.tokenStorage)}`
        }
      });

    }

    // kezeljük a requestet, ahol a tap segítségével elkapjuk a feldolgozatlan visszatérést és a státsuzkód alapján szelektálunk
    return next.handle(request).pipe(
      tap(
        event => {

          // megnézzük, hogy HttpResponse-e a típus, mert ha nem akkor elengedjük az egészet tovább.
          // minden mást a későbbiekben itt tudunk majd lekezelni
          if (event instanceof HttpResponse) {
            // console.log('A visszatérés HttpResponse, a státusza:', event.status, request);
          }

        },
        error => {

          console.log('A visszatérésnél hiba történt, amelynek státusza:', error.status, error.message);

          switch (error.status) {
            case 401: 

              // 401 esetén lövünk egy Logout actiont
              this.store.dispatch(new Logout());
              
              break;
            case 403:

              // 403 esetén egyelőre nem csinálunk semmit
              const err = new PermissionError();

              err.setHeader('printportal_permission_error_header');
              err.setMessage('printportal_permission_error_text');
             
              this.coreService.setResourceError(err);
              break;
          }

        }
      )
    );
  }
}
