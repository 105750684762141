export interface AccessConfigItem {
    route: string;
    scope: string;
}

export class AccessConfig {
    public defaults: AccessConfigItem[] = [
        { route: '/admin/translate', scope: 'pp:access' },
        { route: '/admin/barmi', scope: 'bubo:bibo' }
    ];

    public get configs(): AccessConfigItem[] {
        return this.defaults;
    }
}