import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SecurityDirective } from './shared/directives/security.directive';
import { TableColumnDirective } from './shared/directives/table-column.directive';
import { TableDetailsDirective } from './shared/directives/table-details.directive';
import { TableColorLabelDirective } from './shared/directives/table-color-label.directive';
import { TableInlineIconDirective } from './shared/directives/table-inline-icon.directive';

import { CoreTranslateModule, TranslatePipe, TranslateWithTagPipe } from '@ratkaiga/core/translate';
import { CoreDateTimeModule, DateformatPipe } from '@ratkaiga/core/datetime';

import { ErrorPanelComponent } from '../pages/_layout/partials/error-panel/error-panel.component';

import {
  TableSortIconComponent,
  TablePaginatorComponent,
  TablePaginatorV2Component,
  TableInlineButtonComponent,
  TableInlineIconComponent,
  TableColumnVisibilityCheckboxComponent,

  TableDetailsJobsComponent,
  TableJoblistRowComponent,

  ModalHeaderComponent,
  ModalYesNoComponent,
  LoadingSpinnerComponent,
  UploadProgressComponent,

  PrintportalJobsStatComponent,
  PrintportalOfferDetailsWidgetComponent,
  PrintportalOfferDetailsNagyszovegWidgetComponent,
  TableInvoiceRowComponent,
  ChangelogComponent,
  ModalConnectToPPComponent,
  UploadProgressWidgetComponent
} from '../pages/_layout/widgets';

import {
  SvgIconContainerComponent,
  SvgListItemComponent,
  ChartPieComponent,
  ChartColumnComponent,
  CallasPlateListItemComponent,
  CallasFileThumbnailListComponent,
  CountrySelectComponent,
  BoxedUserSelectComponent,
  BoxedUserSelectFormComponent,
  BoxedPartnerSelectFormComponent,
  BoxedPartnerAddressFormComponent,
  FormControlErrorComponent,
  FormAlertComponent,
  BoxedPartnerAddressSelectComponent,
  AddressFormComponent,
  BoxedUserRightsFormComponent,
  BoxedUserProfileFormComponent
} from '../pages/_layout/partials';

import { InlineSVGModule } from 'ng-inline-svg';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgScrollbarModule } from 'ngx-scrollbar';

import {
  NgbModule,
  NgbDatepickerModule,
  NgbDropdownModule,
  NgbDatepicker,
  NgbDropdown, 
  NgbDropdownMenu, 
  NgbDropdownToggle, 
  NgbDropdownItem
} from '@ng-bootstrap/ng-bootstrap';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NodataPipe } from './shared/pipes/nodata.pipe';
import { CurrencyPipe } from './shared/pipes/currency.pipe';
import { TranslateTagPipe } from './shared/pipes/tagtranslate.pipe';
import { SanitizerService } from './shared/services';
import { FileserviceimagePipe } from './shared/pipes/fileserviceimage.pipe';
import { RounderPipe } from './shared/pipes/rounder.pipe';
import { PinScrollDirective } from './shared/directives/pinscroll.directive';
import { TruncatePipe } from './shared/pipes/truncate.pipe';

@NgModule({
  imports: [
    CommonModule,
    CoreTranslateModule,
    CoreDateTimeModule,
    RouterModule,

    // metronic
    InlineSVGModule,

    FormsModule,
    ReactiveFormsModule,

    // ng-bootstrap
    NgbModule,
    NgbDatepickerModule,
    NgbDropdownModule,

    // apexcharts
    NgApexchartsModule,

    // scrollbar
    NgScrollbarModule
  ],
  declarations: [

    // custom components
    TableSortIconComponent,
    TablePaginatorComponent,
    TablePaginatorV2Component,
    TableInlineButtonComponent,
    TableInlineIconComponent,
    TableColumnVisibilityCheckboxComponent,

    // widget sections
    TableDetailsJobsComponent,
    TableJoblistRowComponent,
    TableInvoiceRowComponent,
    
    // goodies
    ModalHeaderComponent,
    ModalYesNoComponent,
    ModalConnectToPPComponent,
    LoadingSpinnerComponent,
    UploadProgressComponent,
    ChangelogComponent,

    // printportal
    PrintportalJobsStatComponent,
    PrintportalOfferDetailsWidgetComponent,
    PrintportalOfferDetailsNagyszovegWidgetComponent,

    // partials
    SvgIconContainerComponent,
    SvgListItemComponent,
    ChartPieComponent,
    ChartColumnComponent,
    CallasPlateListItemComponent,
    CallasFileThumbnailListComponent,
    CountrySelectComponent,
    BoxedUserRightsFormComponent,
    BoxedUserSelectComponent,
    BoxedUserSelectFormComponent,
    BoxedPartnerSelectFormComponent,
    BoxedPartnerAddressFormComponent,
    BoxedPartnerAddressSelectComponent,
    BoxedUserProfileFormComponent,
    FormControlErrorComponent,
    FormAlertComponent,
    ErrorPanelComponent,
    AddressFormComponent,
    UploadProgressWidgetComponent,

    // directives
    SecurityDirective,
    TableDetailsDirective,
    TableColumnDirective,
    TableColorLabelDirective,
    TableInlineIconDirective,
    NodataPipe,
    CurrencyPipe,
    TranslateTagPipe,
    FileserviceimagePipe,
    RounderPipe,
    PinScrollDirective,
    TruncatePipe
  ],
  exports: [

    // custom components
    TableSortIconComponent,
    TablePaginatorComponent,
    TablePaginatorV2Component,
    TableInlineButtonComponent,
    TableInlineIconComponent,
    TableColumnVisibilityCheckboxComponent,
      
    // widget sections
    TableDetailsJobsComponent,
    TableJoblistRowComponent,
    TableInvoiceRowComponent,

    // goodies
    ModalHeaderComponent,
    ModalYesNoComponent,
    ModalConnectToPPComponent,
    LoadingSpinnerComponent,
    UploadProgressComponent,
    ChangelogComponent,

    // printportal
    PrintportalJobsStatComponent,
    PrintportalOfferDetailsWidgetComponent,
    PrintportalOfferDetailsNagyszovegWidgetComponent,

    // partials
    SvgIconContainerComponent,
    SvgListItemComponent,
    ChartPieComponent,
    ChartColumnComponent,
    CallasPlateListItemComponent,
    CallasFileThumbnailListComponent,
    CountrySelectComponent,
    BoxedUserRightsFormComponent,
    BoxedUserSelectComponent,
    BoxedUserSelectFormComponent,
    BoxedPartnerSelectFormComponent,
    BoxedPartnerAddressFormComponent,
    BoxedPartnerAddressSelectComponent,
    BoxedUserProfileFormComponent,
    FormControlErrorComponent,
    FormAlertComponent,
    ErrorPanelComponent,
    AddressFormComponent,
    UploadProgressWidgetComponent,

    // directives
    SecurityDirective,
    TableDetailsDirective,
    TableColumnDirective,
    TableColorLabelDirective,
    TableInlineIconDirective,

    // core pipes
    TranslatePipe,
    TranslateWithTagPipe,
    DateformatPipe,
    NodataPipe,
    CurrencyPipe,
    TranslateTagPipe,
    FileserviceimagePipe,
    RounderPipe,
    PinScrollDirective,
    TruncatePipe,
    
    // Ngb?
    NgbDropdown, 
    NgbDropdownMenu, 
    NgbDropdownToggle, 
    NgbDropdownItem
  ],
  providers: [
    NgbDatepicker,
    NgbDropdown,
    SanitizerService
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class ScrollCoreModule {
}
