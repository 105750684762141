export * from './environment.interfaces';

// aux interfaces
export * from './auth-notice.interface';

// callas interfaces
export * from './callas.interface';

// printportal interfaces
export * from './printportal.interface';

// modal interfaces & classes
export * from './modal.interface';

// table interfaces
export * from './table-button.interface';
export * from './table-columns.interface';
export * from './table-details-component.interface';
export * from './table-paginator-options.interface';