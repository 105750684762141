import { Injectable } from '@angular/core';

import { CoreTranslateService } from '@ratkaiga/core/translate';
import { environment } from 'src/environments/environment';
import { Allapot, IAllapot, SzamlaAllapotEnum, TaskaAllapotEnum, TaskaInterakcioAllapotEnum } from '../../config/allapot.enums';
import { EnvironmentService } from './environment.service';

@Injectable({
    providedIn: 'root'
})
export class EnumService {

    private allapotList: IAllapot[] = [];

    public get allapotok(): IAllapot[] {
        return this.allapotList;
    }

    constructor(private translateService: CoreTranslateService, private environmentService: EnvironmentService) {
        this.initGlobalEnumerations();
    }

    private initGlobalEnumerations(): void {

        this.allapotList = [];
        // alapértelmezett PP táska állapotok
        this.allapotList.push(new Allapot().setKey(TaskaAllapotEnum.AKTIV).setPrefix('taska_allapot').setLabel(TaskaAllapotEnum.AKTIV));
        this.allapotList.push(new Allapot().setKey(TaskaAllapotEnum.ELOKESZITES).setPrefix('taska_allapot').setLabel(TaskaAllapotEnum.ELOKESZITES));
        this.allapotList.push(new Allapot().setKey(TaskaAllapotEnum.KESZ).setPrefix('taska_allapot').setLabel(TaskaAllapotEnum.KESZ));
        this.allapotList.push(new Allapot().setKey(TaskaAllapotEnum.KOTESZET).setPrefix('taska_allapot').setLabel(TaskaAllapotEnum.KOTESZET));
        this.allapotList.push(new Allapot().setKey(TaskaAllapotEnum.LESZALLITVA).setPrefix('taska_allapot').setLabel(TaskaAllapotEnum.LESZALLITVA));
        this.allapotList.push(new Allapot().setKey(TaskaAllapotEnum.MEGRENDELVE).setPrefix('taska_allapot').setLabel(TaskaAllapotEnum.MEGRENDELVE));
        this.allapotList.push(new Allapot().setKey(TaskaAllapotEnum.NYOMAS).setPrefix('taska_allapot').setLabel(TaskaAllapotEnum.NYOMAS));
        this.allapotList.push(new Allapot().setKey(TaskaAllapotEnum.SZALLITHATO).setPrefix('taska_allapot').setLabel(TaskaAllapotEnum.SZALLITHATO));

        // alapértelmezett PP számla állapotok
        this.allapotList.push(new Allapot().setKey(SzamlaAllapotEnum.NYITOTT).setPrefix('szamla_allapot').setLabel(SzamlaAllapotEnum.NYITOTT));
        this.allapotList.push(new Allapot().setKey(SzamlaAllapotEnum.KIEGYENLITETT).setPrefix('szamla_allapot').setLabel(SzamlaAllapotEnum.KIEGYENLITETT));
        this.allapotList.push(new Allapot().setKey(SzamlaAllapotEnum.LEJART).setPrefix('szamla_allapot').setLabel(SzamlaAllapotEnum.LEJART));
        this.allapotList.push(new Allapot().setKey(SzamlaAllapotEnum.SZTORNO).setPrefix('szamla_allapot').setLabel(SzamlaAllapotEnum.SZTORNO));

        // taska lista allapotok lehetnek (még)
        this.allapotList.push(new Allapot().setKey(TaskaAllapotEnum.FOLYAMATBAN).setPrefix('taska_allapot').setLabel(TaskaAllapotEnum.FOLYAMATBAN));
        this.allapotList.push(new Allapot().setKey(TaskaAllapotEnum.PROBLEMA).setPrefix('taska_allapot').setLabel(TaskaAllapotEnum.PROBLEMA));

        this.allapotList.push(new Allapot().setKey(TaskaInterakcioAllapotEnum.AKTIV.toString()).setPrefix('taska_interakcio_allapot').setLabel(TaskaAllapotEnum.AKTIV));
        this.allapotList.push(new Allapot().setKey(TaskaInterakcioAllapotEnum.FOLYAMATBAN.toString()).setPrefix('taska_interakcio_allapot').setLabel(TaskaAllapotEnum.FOLYAMATBAN));
        this.allapotList.push(new Allapot().setKey(TaskaInterakcioAllapotEnum.PROBLEMA.toString()).setPrefix('taska_interakcio_allapot').setLabel(TaskaAllapotEnum.PROBLEMA));
        this.allapotList.push(new Allapot().setKey(TaskaInterakcioAllapotEnum.TOROLT.toString()).setPrefix('taska_interakcio_allapot').setLabel(TaskaAllapotEnum.TOROLT));
        this.allapotList.push(new Allapot().setKey(TaskaInterakcioAllapotEnum.RENDBEN.toString()).setPrefix('taska_interakcio_allapot').setLabel(TaskaAllapotEnum.RENDBEN));
        this.allapotList.push(new Allapot().setKey(TaskaInterakcioAllapotEnum.NEM_SZUKSEGES.toString()).setPrefix('taska_interakcio_allapot').setLabel(TaskaAllapotEnum.NEM_SZUKSEGES));

        // keskeny táska állapotok
        if (this.environmentService.showOnlySpecifiedVersions('keskeny')) {
            this.allapotList.push(new Allapot().setKey(TaskaAllapotEnum.FELDOLOGOZAS).setPrefix('taska_allapot').setLabel(TaskaAllapotEnum.FELDOLOGOZAS));
            this.allapotList.push(new Allapot().setKey(TaskaAllapotEnum.PROGRAMOZHATO).setPrefix('taska_allapot').setLabel(TaskaAllapotEnum.PROGRAMOZHATO));
            this.allapotList.push(new Allapot().setKey(TaskaAllapotEnum.TERMELESBEN).setPrefix('taska_allapot').setLabel(TaskaAllapotEnum.TERMELESBEN));
            this.allapotList.push(new Allapot().setKey(TaskaAllapotEnum.SZALLITANI).setPrefix('taska_allapot').setLabel(TaskaAllapotEnum.SZALLITANI));
            this.allapotList.push(new Allapot().setKey(TaskaAllapotEnum.FELTOLTOTT).setPrefix('taska_allapot').setLabel(TaskaAllapotEnum.FELTOLTOTT));
            this.allapotList.push(new Allapot().setKey(TaskaAllapotEnum.SZAMLAZNI).setPrefix('taska_allapot').setLabel(TaskaAllapotEnum.SZAMLAZNI));
        }

    }

    /**
     * Egy adott táska állapot megnevezését adjuk vissza a translateService-n keresztül
     * 
     * @param key 
     * @returns 
     */
    getAllapotLabel(key?: string, prefix?: string): string {

        let r;
        // tslint:disable-next-line:no-shadowed-variable
        if (prefix) {
            r = this.allapotList.filter((r) => r.key === key && r.prefix === prefix).shift();
        } else {
            r = this.allapotList.filter((r) => r.key === key).shift();
        }

        if (r === undefined || r === null) {
            return this.translateService.translate('enum_label_no_data');
        } else {
            return this.translateService.translate(r.label);
        }
        
    }

}