import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { IPrintportalFormValidatorOptions, PrintportalFieldErrorEntity } from 'src/app/_core/shared/interfaces/printportal.interface';

@Component({
  selector: 'kt-form-control-error',
  templateUrl: './form-control-error.component.html',
  styleUrls: ['./form-control-error.component.scss']
})
export class FormControlErrorComponent implements OnInit, OnChanges, OnDestroy {

  destroy$: Subject<void> = new Subject();

  @Input() field: string;
  @Input() control: FormControl;
  @Input() description: string;
  @Input() errors: PrintportalFieldErrorEntity[];

  @Input() options: {
    minLenght?: number,
    maxLength?: number,
    type?: string,
  };

  _options: IPrintportalFormValidatorOptions;
  customError = '';

  constructor() {
    // silent
  }

  ngOnChanges(): void {
    
    if (this.field) {
      this.customError = '';
      this.errors?.forEach((e) => {
        if (e.getField() === this.field) {
          this.customError = e.getCode();
        }
      });
    }

  }

  ngOnInit(): void {

    // options alapértelmezések minden validátorhoz
    this._options = {
      minLength: 3,
      maxLength: 1024,
      type: 'text'
    }

    // egyedi options objektum merge a belső optionsba
    this._options = { ...this._options, ...this.options };

    this.control.statusChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      if (this.control) {
        this.customError = '';
        if (this.control.errors?.notSame) {
            this.customError = 'A megadott jelszavaknak meg kell egyeznie';
        }      
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  showMessage(control: FormControl): boolean {    
    return (control.invalid && (control.pristine || control.untouched)) || !control.invalid;
  }

}
