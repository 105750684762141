import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { map, take } from "rxjs/operators";

import { EntityService, CoreResponse } from '@ratkaiga/core';
import { environment } from "src/environments/environment";
import { CoreService } from "./shared/services";
import { PreloadEntity } from "./entities/preload.entity";

@Injectable({ providedIn: 'root' })
export class PPPreloadResolver implements Resolve<CoreResponse> {

  constructor(private http:HttpClient, private entityService: EntityService, private coreService: CoreService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any>|Promise<any>|any {

    if (this.coreService.currentNyomdaId()) {
      return this.http.get(`${environment.endpoints.app}/pp_preload_v2/${this.coreService.currentNyomdaId()}`)
      .pipe(map((r) => { 
        
          this.coreService.setPreloadEntity(r['data'].payload as PreloadEntity);
  
        return r['data'].payload as PreloadEntity;
      }));
    } else {
      return of(new PreloadEntity());
    }

  }
}