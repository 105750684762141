import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './_core/shared/guards/auth.guard';
import { PPPreloadResolver } from './_core/preload-resolver';

export const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'error',
    loadChildren: () =>
      import('./modules/errors/errors.module').then((m) => m.ErrorsModule),
  },
  // https://printportal.hu/meghivo/8C5BD4B2E68CF70E0E0DF190996C0025/egyjog/ajanlat?megrendeles=1307
  // todo: meghívó
  {
    path: '',
    canActivate: [AuthGuard],
    resolve: {
      data: PPPreloadResolver
    },
    loadChildren: () =>
      import('./pages/layout.module').then((m) => m.LayoutModule),
  },
  { path: '**', redirectTo: 'errors/404', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
