export interface IAllapot {

    key: TaskaAllapotType | SzamlaAllapotType;

    prefix: string;

    label: string;

    url?: string;

    template?: string;

    setKey(key: string): Allapot;

    setPrefix(prefix: string): Allapot;

    setLabel(key: string): Allapot;

    setUrl(url: string): Allapot;

    setTemplate(template: string): Allapot;

    getUrl(): string;

    getTemplate(): string;
}

export class Allapot implements IAllapot {

    key: TaskaAllapotType | SzamlaAllapotType;

    prefix = 'allapot';

    label: string;

    url?: string;

    template?: string;

    public get getLabel(): string {
        return this.label;
    }

    setKey(key: string): Allapot {
        this.key = key;
        return this;
    }

    setPrefix(prefix: string): Allapot {
        this.prefix = prefix;
        return this;
    }

    setLabel(key: string): Allapot {
        this.label = 'enum_label_' + this.prefix + '_' + key;
        return this;
    }

    setUrl(url: string): Allapot {
        this.url = url;
        return this;
    }

    setTemplate(template: string): Allapot {
        this.template = template;
        return this;
    }

    getUrl(): string {
        return this.url;
    }

    getTemplate(): string {
        return this.template;
    }

}

export type TaskaAllapotType = string |
    TaskaAllapotEnum.AKTIV |
    TaskaAllapotEnum.SZALLITHATO |
    TaskaAllapotEnum.KESZ |
    TaskaAllapotEnum.ELOKESZITES |
    TaskaAllapotEnum.NYOMAS |
    TaskaAllapotEnum.KOTESZET |
    TaskaAllapotEnum.MEGRENDELVE |
    TaskaAllapotEnum.LESZALLITVA |
    TaskaAllapotEnum.FOLYAMATBAN |
    TaskaAllapotEnum.PROBLEMA | 
    TaskaAllapotEnum.TOROLT | 
    TaskaAllapotEnum.RENDBEN | 
    TaskaAllapotEnum.NEM_SZUKSEGES | 
    TaskaAllapotEnum.ALVALLALKOZO |

    // keskeny speciális
    TaskaAllapotEnum.FELDOLOGOZAS | 
    TaskaAllapotEnum.PROGRAMOZHATO | 
    TaskaAllapotEnum.TERMELESBEN | 
    TaskaAllapotEnum.SZALLITANI | 
    TaskaAllapotEnum.FELTOLTOTT | 
    TaskaAllapotEnum.SZAMLAZNI;

export enum TaskaAllapotEnum {
    AKTIV = 'aktiv',
    SZALLITHATO = 'szallithato',
    KESZ = 'kesz',
    ELOKESZITES = 'elokeszites',
    NYOMAS = 'nyomas',
    KOTESZET = 'koteszet',
    MEGRENDELVE = 'megrendelve',
    LESZALLITVA = 'leszallitva',
    FOLYAMATBAN = 'folyamatban',
    PROBLEMA = 'problema',
    NEM_SZUKSEGES = 'nem_szukseges',
    TOROLT = 'torolt',
    RENDBEN = 'rendben',
    ALVALLALKOZO = 'alvallalkozo',
    
    // keskeny speciális
    FELDOLOGOZAS = 'feldolgozas',
    PROGRAMOZHATO = 'programozhato',
    TERMELESBEN = 'termelesben',
    SZALLITANI = 'szallitani',
    FELTOLTOTT = 'feltoltott',
    SZAMLAZNI = 'szamlazni'
}


export class TaskaAllapotToType {
    public static getAllapotColor(allapot: TaskaAllapotType): string {
        switch(allapot) {
            case TaskaAllapotEnum.AKTIV:
            case TaskaAllapotEnum.ELOKESZITES:
            case TaskaAllapotEnum.FOLYAMATBAN:
            case TaskaAllapotEnum.KOTESZET:
            case TaskaAllapotEnum.NYOMAS:
            case TaskaAllapotEnum.ALVALLALKOZO:
                return 'label-light-info';
                break;
            case TaskaAllapotEnum.KESZ:
            case TaskaAllapotEnum.SZALLITHATO:
                return 'label-light-warning';
                break;
            case TaskaAllapotEnum.LESZALLITVA:
                return 'label-light-success';
                break;
            case TaskaAllapotEnum.PROBLEMA:
                return 'label-light-danger';
                break;
            default: 
                return 'label-light-primary';
                break;
        }
    }

    public static getAllapotColorKeskeny(allapot: TaskaAllapotType): string {
        switch(allapot) {
            case TaskaAllapotEnum.AKTIV:
            case TaskaAllapotEnum.ELOKESZITES:
            case TaskaAllapotEnum.FOLYAMATBAN:
            case TaskaAllapotEnum.KOTESZET:
            case TaskaAllapotEnum.NYOMAS:
                return 'label-light-info';
                break;
            case TaskaAllapotEnum.KESZ:
            case TaskaAllapotEnum.SZALLITHATO:
                return 'label-light-warning';
                break;
            case TaskaAllapotEnum.LESZALLITVA:
                return 'label-light-success';
                break;
            case TaskaAllapotEnum.PROBLEMA:
                return 'label-light-danger';
                break;
            default: 
                return 'label-light-primary';
                break;
        }
    }

    public static getAllapotEnum(allapot: string): TaskaAllapotType {
        switch (allapot) {
            case 'Alvállalkozó':
                return TaskaAllapotEnum.FOLYAMATBAN;
                break;
            case 'Aktív':
                return TaskaAllapotEnum.AKTIV;
            break;
            case 'Szállítható':
                return TaskaAllapotEnum.SZALLITHATO;
                break;
            case 'Kész':
                return TaskaAllapotEnum.KESZ;
                break;
            case 'Előkészítés':
                return TaskaAllapotEnum.ELOKESZITES;
                break;
            case 'Nyomás':
                return TaskaAllapotEnum.NYOMAS;
                break;
            case 'Kötészet':
                return TaskaAllapotEnum.KOTESZET;
                break;
            case 'Megrendelve':
                return TaskaAllapotEnum.MEGRENDELVE;
                break;
            case 'Leszállítva':
                return TaskaAllapotEnum.LESZALLITVA;
                break;
            case 'Folyamatban':
                return TaskaAllapotEnum.FOLYAMATBAN;
                break;
            case 'Probléma':
                return TaskaAllapotEnum.PROBLEMA;
                break;
            case 'Feldolgozás': 
                return TaskaAllapotEnum.FELDOLOGOZAS;
                break;
            case 'Programozható':
                return TaskaAllapotEnum.PROGRAMOZHATO;
                break;
            case 'Termelésben':
                return TaskaAllapotEnum.TERMELESBEN;
                break;
            case 'Szállítani':
                return TaskaAllapotEnum.SZALLITANI;
                break;
            case 'Feltöltött':
                return TaskaAllapotEnum.FELTOLTOTT;
                break;
            case 'Számlázni':
                return TaskaAllapotEnum.SZAMLAZNI;
                break;
            default:

                break;
        }
    }

    public static getAllapotEnumKeskeny(allapot: string): TaskaAllapotType {
        switch (allapot) {
            case 'Aktív':
                return TaskaAllapotEnum.AKTIV;
            break;
            case 'Szállítható':
                return TaskaAllapotEnum.KESZ;
                break;
            case 'Kész':
                return TaskaAllapotEnum.KESZ;
                break;
            case 'Előkészítés':
                return TaskaAllapotEnum.FOLYAMATBAN;
                break;
            case 'Nyomás':
                return TaskaAllapotEnum.FOLYAMATBAN;
                break;
            case 'Kötészet':
                return TaskaAllapotEnum.FOLYAMATBAN;
                break;
            case 'Megrendelve':
                return TaskaAllapotEnum.MEGRENDELVE;
                break;
            case 'Leszállítva':
                return TaskaAllapotEnum.LESZALLITVA;
                break;
            case 'Folyamatban':
                return TaskaAllapotEnum.FOLYAMATBAN;
                break;
            case 'Probléma':
                return TaskaAllapotEnum.PROBLEMA;
                break;
            case 'Feldolgozás': 
                return TaskaAllapotEnum.MEGRENDELVE;
                break;
            case 'Programozható':
                return TaskaAllapotEnum.FOLYAMATBAN;
                break;
            case 'Termelésben':
                return TaskaAllapotEnum.FOLYAMATBAN;
                break;
            case 'Szállítani':
                return TaskaAllapotEnum.KESZ;
                break;
            case 'Feltöltött':
                return TaskaAllapotEnum.FELTOLTOTT;
                break;
            case 'Számlázni':
                return TaskaAllapotEnum.KESZ;
                break;
        }
    }
}

export type SzamlaAllapotType = string |
    SzamlaAllapotEnum.NYITOTT |
    SzamlaAllapotEnum.KIEGYENLITETT |
    SzamlaAllapotEnum.LEJART |
    SzamlaAllapotEnum.SZTORNO;

export enum SzamlaAllapotEnum {
    NYITOTT = 'nyitott',
    KIEGYENLITETT = 'kiegyenlitett',
    LEJART = 'lejart',
    SZTORNO = 'sztorno',
}

export type TaskaInterakcioAllapotType = number | 
    TaskaInterakcioAllapotEnum.AKTIV |
    TaskaInterakcioAllapotEnum.FOLYAMATBAN | 
    TaskaInterakcioAllapotEnum.PROBLEMA | 
    TaskaInterakcioAllapotEnum.RENDBEN | 
    TaskaInterakcioAllapotEnum.NEM_SZUKSEGES;

export enum TaskaInterakcioAllapotEnum {
    AKTIV = 0,
    FOLYAMATBAN = 1,
    PROBLEMA = 2,
    RENDBEN = 3,
    TOROLT = 4,
    NEM_SZUKSEGES = 5
}

export class TaskaInterakcioAllapotToType {
    public static getAllapotColor(allapot: TaskaInterakcioAllapotType): string {
        switch(allapot) {
            case TaskaInterakcioAllapotEnum.AKTIV:
                return 'label-light-info';
                break;
            case TaskaInterakcioAllapotEnum.RENDBEN:
                return 'label-light-success';
                break;
            case TaskaInterakcioAllapotEnum.TOROLT:
                return 'label-light-warning';
                break;
            case TaskaInterakcioAllapotEnum.PROBLEMA:
                return 'label-light-danger';
                break;
            default: 
                return 'label-light-primary';
                break;
        }
    }
}