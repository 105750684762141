export interface INgScrollParams {
    opcionev: string;
    ertek: string;
}

export interface INgScrollWebAllapotok {
    id?: number;
    nev: string;
    webnev: string;
}

export class PreloadEntity {

    id: number;
   
    weballapotok: INgScrollWebAllapotok[] = [];

    params: INgScrollParams[] = [];

}