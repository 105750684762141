import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Observable, Subject } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { AppState } from '../../../_core/store';
import { AuthNoticeService, AuthService, Login } from '../../../_core/auth';
import { CoreTranslateService } from '@ratkaiga/core/translate';
import { environment } from 'src/environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { SanitizerService } from 'src/app/_core/shared/services';

@Component({
	selector: 'kt-login',
	templateUrl: './login.component.html',
	encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit, OnDestroy {

	loginForm: FormGroup;
	loading = false;
	isLoggedIn$: Observable<boolean>;
	errors: any = [];

	languageSelector = false;
	languages: { value: string, label: string }[] = [];

	private unsubscribe: Subject<any>;

	private returnUrl: any;

	/**
	 * Component constructor
	 *
	 * @param router: Router
	 * @param auth: AuthService
	 * @param authNoticeService: AuthNoticeService
	 * @param translate: TranslateService
	 * @param store: Store<AppState>
	 * @param fb: FormBuilder
	 * @param cdr: ChangeDetectorRef
	 * @param route: ActivatedRoute
	 */
	constructor(
		private router: Router,
		private auth: AuthService,
		private authNoticeService: AuthNoticeService,
		private store: Store<AppState>,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private route: ActivatedRoute,
		private translateService: CoreTranslateService
	) {
		this.unsubscribe = new Subject();
	}

	ngOnInit(): void {

		this.languageSelector = environment.layout.featureConfigs.multiLanguage;

		// ha nincs még local storage bejegyzésünk az aktuális nyelvre akkor létrehozunk egyet
		if (!localStorage.getItem(environment.localStorage.translationStorage)) {
			localStorage.setItem(environment.localStorage.translationStorage, this.translateService.getCurrentLocale());
		}

		this.initLoginForm();

		// ha volt returnUrl a paramsban akkor azt tároljuk a komponensen belül a this.returnUrl változóban
		this.route.queryParams.subscribe(params => {
			this.returnUrl = params.returnUrl || '/';
		});

		this.languages = [];
		environment.layout.localization[environment.layout.version].languages.forEach(lang => {
			this.languages.push({ value: lang.toLowerCase(), label: this.translateService.translate('option_lang_' + lang.toLowerCase()) });
		});
	}

	ngOnDestroy(): void {
		this.authNoticeService.setNotice(null);
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}

	/**
	 * Inicializáljuk az űrlapot, beállítjuk az alapértelmezett értékeket és a validátorokat
	 * 
	 * @see https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
	 */
	initLoginForm(): void {

		this.loginForm = this.fb.group({
			email: ['', Validators.compose([
				Validators.required,
				Validators.email,
				Validators.minLength(3),
				Validators.maxLength(320)
			])
			],
			password: ['', Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(100)
			])
			]
		});
	}

	changeLanguage(value: string): void {
		if (this.translateService.setCurrentLocale(value)) {
			this.cdr.detectChanges();
		}
	}

	/**
	 * Az űrlap elküldése a backend felé, amennyiben a validátorok megfelelőnek találják.
	 */
	submit(): void {
		const controls = this.loginForm.controls;

		// megnézzük, hogy az űrlap értékek között van-e invalid. ha találunk ilyet akkor 
		// azt flageljük a markAsTouched segítégével
		if (this.loginForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}

		// a betöltés spinnert igazra állítjuk, ezzel majd később foglalkozunk tovább
		this.loading = true;

		// meghívjuk az auth service login funkcióját, amely visszatérése egy obszerver, 
		// tehát rögtön el is kapjuk a végeredményt és feldolgozzuk.
		this.auth
			.login(SanitizerService.serialize(controls.email.value), controls.password.value)
			.pipe(
				tap(user => {
					if (user) {

						// ha van user akkor dispatcholunk egy új Login actiont, aminek a payload értéke az access_token lesz
						// utána automatikusan elirányítjuk a felhasználót a returnUrl-ben szereplő címre, ami alapértelmezésben "/" lesz
						this.store.dispatch(new Login({ authToken: user.access_token }));
						this.router.navigateByUrl(this.returnUrl);
					} else {

						// ha nincsen felhasználónk, akkor megjelenítjük a hibaüzenetet
						this.authNoticeService.setNotice(this.translateService.translate('ERROR.AUTH.INVALID_CREDENTIALS'), 'danger');
					}
				}),
				takeUntil(this.unsubscribe),
				finalize(() => {
					this.loading = false;
					this.cdr.markForCheck();
				})
			)
			.subscribe(
				() => {
					// nem teszünk semmit a results ágban
				},
				(err) => {
					if (err instanceof HttpErrorResponse) {
						this.authNoticeService.setNotice(this.translateService.translate('ERROR.AUTH.INVALID_CREDENTIALS'), 'danger');
					} else {
						this.authNoticeService.setNotice(this.translateService.translate('ERROR.ANGULAR.GENERAL_ERROR'), 'danger');
					}
				}
			);
	}

	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.loginForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}
}
