import { Component, ElementRef, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { SplashScreenService } from 'src/app/_metronic/partials/layout/splash-screen/splash-screen.service';
import { AuthNoticeService } from '../../_core/auth';

// environment import
import { environment } from 'src/environments/environment';

// interface imports
import { IEnvironmentAssets } from 'src/app/_core/shared/interfaces';

// service imports
import { EnvironmentService } from 'src/app/_core/shared/services';

@Component({
	selector: 'kt-auth',
	templateUrl: './auth.component.html',
	styleUrls: ['./auth.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class AuthComponent implements OnInit {

	today: number = Date.now();

	assets: IEnvironmentAssets;

	/**
	 * Component constructor
	 *
	 * @param el
	 * @param render
	 * @param layoutConfigService: LayoutConfigService
	 * @param authNoticeService: authNoticeService
	 * @param splashScreenService: SplashScreenService
	 * @param environmentService: EnvironmentService
	 */
	constructor(
		private el: ElementRef,
		private render: Renderer2,
		public authNoticeService: AuthNoticeService,
		private splashScreenService: SplashScreenService,
		private environmentService: EnvironmentService) {
	}

	ngOnInit(): void {
		// splash screen-t eltüntetjük
		this.splashScreenService.hide();

		// elővesszük a specifikus asseteket a login képernyőhöz, ezt az environment layout részben tudjuk szabályozni
		this.assets = this.environmentService.getAssets();
	}

	/**
	 * Load CSS for this specific page only, and destroy when navigate away
	 * 
	 * @param styleUrl
	 */
	private loadCSS(styleUrl: string) {
		return new Promise((resolve, reject) => {
			const styleElement = document.createElement('link');
			styleElement.href = styleUrl;
			styleElement.type = 'text/css';
			styleElement.rel = 'stylesheet';
			styleElement.onload = resolve;
			this.render.appendChild(this.el.nativeElement, styleElement);
		});
	}
}
