<div class="d-flex flex-column flex-root" id="kt_login_wrapper">
	<div class="login login-3 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white" id="kt_login">

		<!-- begin: háttér, logó, stb. -->
		<div class="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10" [style.background-image]="'url(' + assets.login.layout.background + ')'">

			<div class="d-flex flex-row-fluid flex-column justify-content-between">

				<!-- begin: felső logo -->
				<span class="flex-column-auto mt-5 w-100" [class.text-center]="assets.login.logo.centered">
					<img src="{{ assets.login.logo.url }}" class="{{ assets.login.logo.class }}" />
				</span>
				<!-- end: felső logo -->

				<!-- begin: tartalom -->
				<div class="flex-column-fluid d-flex flex-column justify-content-center">
					<h3 class="font-size-h1 mb-5 {{ assets.login.layout.textclass }}" [class.text-dark]="assets.login.layout.theme === 'dark'" [class.text-light]="assets.login.layout.theme === 'light'">{{ 'header_printportal_welcome' | translate }}</h3>
					<p class="font-weight-lighter opacity-80 {{ assets.login.layout.textclass }}" [class.text-dark-75]="assets.login.layout.theme === 'dark'" [class.text-light]="assets.login.layout.theme === 'light'">
						{{ 'label_printportal_welcome' | translate }}
					</p>
				</div>
				<!-- end: tartalom -->

				<!-- begin: powered by -->
				<div class="d-block w-100 center text-center" *ngIf="assets.login.layout.usePoweredBy">
					<img src="/assets/images/scroll-sm.png" class="max-h-70px" />
				</div>
				<!-- end: powered by -->

				<!--begin: tartalom foooter desktop felbontásra -->
				<div class="d-none flex-column-auto d-lg-flex justify-content-between mt-10">

					<div class="opacity-70 font-weight-bold {{ assets.login.layout.textclass }}" [class.text-dark-75]="assets.login.layout.theme === 'dark'" [class.text-light]="assets.login.layout.theme === 'light'">
						&copy; {{ 'label_printportal_copyright' | translate }}
					</div>
					<div class="d-flex">
						<a [attr.href]="assets.fronturls.privacy" target="_blank" [class.text-dark-75]="assets.login.layout.theme === 'dark'" [class.text-light]="assets.login.layout.theme === 'light'" class="{{ assets.login.layout.textclass }}">{{ 'button_privacy_policy' | translate }}</a>
						<a [attr.href]="assets.fronturls.contact" target="_blank" [class.text-dark-75]="assets.login.layout.theme === 'dark'" [class.text-light]="assets.login.layout.theme === 'light'" class="ml-10 {{ assets.login.layout.textclass }}">{{ 'button_contact' | translate }}</a>
					</div>
				</div>
				<!--end: tartalom foooter desktop felbontásra -->
			</div>

		</div>
		<!-- end: háttér, logó, stb. -->

		<!--begin::Content-->
		<div class="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
			<!--begin::Content header-->
			<div class="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10" style="display: none;">
				<span class="font-weight-bold text-dark-50">
					{{ 'label_question_user_available' | translate }}
				</span>
				<a routerLink="/auth/register" href="javascript:;" class="font-weight-bold ml-2" id="kt_login_signup">{{ 'button_registration' | translate }}</a>
			</div>
			<!--end::Content header-->

			<router-outlet></router-outlet>
			<!--begin::Content footer for mobile-->
			<div class="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
				<div class="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
					&copy; {{ 'label_printportal_copyright' | translate }}
				</div>
				<div class="d-flex order-1 order-sm-2 my-2">
					<a [attr.href]="assets.fronturls.privacy" target="_blank" class="text-dark-75 text-hover-primary">{{ 'button_privacy_policy' | translate }}</a>
					<a [attr.href]="assets.fronturls.contact" target="_blank" class="text-dark-75 text-hover-primary ml-4">{{ 'button_contact' | translate }}</a>
				</div>
			</div>
			<!--end::Content footer for mobile-->
		</div>
		<!--end::Content-->
	</div>
</div>